import React from "react";
import Helmet from "react-helmet";
import Header from "../../components/Header/Header";

function About() {
  return (
    <>
      <Header col={true} />
      <Helmet>
        <title>
          About Us | RKS Motor - Maruti Suzuki Largest Automobile Dealer in
          Telangana
        </title>
        <meta
          name="title"
          content="About Us | RKS Motor - Maruti Suzuki Largest Automobile Dealer in Telangana"
        />
        <meta
          name="description"
          content="Maruti Suzuki Largest Automobile Dealer in Telangana, Hyderabad | Grown over the years ; now has 3 exclusive showrooms, 11 workshops & 204 touch points."
        />
        <meta
          name="keywords"
          content="Maruti dealers in Hyderabad, Nexa Delaers in Hyderabad, Nexa Cars Hyderabad, Nexa Balneo, Nexa Scross, Nexa Ignis, Nexa Ciaz, Nexa Xl6, Maruti baleno, maruti ciaz, maruti ignis, maruti xl6, maruti scross, Rks Motor, SabooRKS, Saboonexa."
        />
        <meta name="author" content="Broaddcast" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.saboonexa.in/about-us/" />
        <meta
          property="og:title"
          content="About Us | RKS Motor - Maruti Suzuki Largest Automobile Dealer in Telangana"
        />
        <meta
          property="og:description"
          content="Maruti Suzuki Largest Automobile Dealer in Telangana, Hyderabad | Grown over the years ; now has 3 exclusive showrooms, 11 workshops & 204 touch points."
        />
        <meta
          property="og:image"
          content="https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/saboonexa/og-tags/About-us.webp"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://www.saboonexa.in/about-us/"
        />
        <meta
          property="twitter:title"
          content="About Us | RKS Motor - Maruti Suzuki Largest Automobile Dealer in Telangana"
        />
        <meta
          property="twitter:description"
          content="Maruti Suzuki Largest Automobile Dealer in Telangana, Hyderabad | Grown over the years ; now has 3 exclusive showrooms, 11 workshops & 204 touch points."
        />
        <meta
          property="twitter:image"
          content="https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/saboonexa/og-tags/About-us.webp"
        />
      </Helmet>
      <div className="pb-[67px]  sm:pb-[120px] lg:pb-[135px]"></div>
      <div className="pt-4 pb-8 text-3xl font-medium text-center uppercase sm:text-4xl md:text-5xl ">
        ABOUT US
      </div>
      {/* <h1 className='pb-8 text-3xl font-bold text-center'>ABOUT US</h1> */}
      <div className="container mx-auto md:py-16">
        <div className="grid gap-2 px-5 bg-gray-100 md:grid-cols-2 lg:gap-8 md:rounded-xl md:shadow-lg md:shadow-gray-600 group">
          <div className="my-10 font-medium leading-loose text-justify text-black md:my-28 indent-8">
            Saboo RKS has been at the forefront of Hyderabad's automotive
            industry, driving growth in automobile sales and service for over
            four decades. With a history dating back to 1973, we began as a
            2-wheeler dealership and have since expanded to include 4-wheelers,
            becoming an esteemed Maruti Suzuki dealer in 1997. Our extensive
            reach throughout the city is comprised of five sales outlets, nine
            workshops, four pre-owned vehicle sales outlets, one commercial
            outlet, and two premium NEXA dealerships.
          </div>
          <div className="pb-4 duration-500 lg:-mt-12 group-hover:grayscale-0 grayscale group-hover:saturate-200">
            <img
              src="https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/nexa/thumbnails/lumbini_outlet.webp"
              // src='https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/saboonexa/Maruti-Suzuki-Nexa_Lumbini.webp'
              className="mb-6 overflow-hidden rounded-md shadow-xl shadow-gray-600 lg:mb-8"
              alt="Maruti-Suzuki-Nexa_Lumbini"
            />
          </div>
        </div>
      </div>

      <div className="container py-16 mx-auto">
        <div className="grid gap-2 px-5 bg-gray-100 md:grid-cols-2 lg:gap-8 m md:rounded-2xl md:shadow-lg md:shadow-gray-600 group">
          <div className="duration-500 lg:-mt-20 group-hover:grayscale-0 grayscale group-hover:saturate-200">
            <img
              src="https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/saboonexa/Maruti-Suzuki-Nexa_Jubilee.webp"
              className=" w-full rounded-xl scale-95 2xl:scale-[0.85] "
              alt="Maruti-Suzuki-Nexa_Jubilee"
            />
          </div>
          <div className="my-10 space-y-4 font-medium leading-loose text-justify text-black md:my-28 indent-8">
            At RKS MOTOR PVT LTD, our unwavering dedication to the "Customers
            for Life" philosophy drives us. With a skilled workforce, advanced
            technology, and commitment to excellence, we aim to add value to
            each interaction, ensuring your contentment. With a rich history of
            service excellence and unwavering satisfaction, we're prepared to
            thrive in the changing business landscape. Our agility in embracing
            transformation and relentless self-reinvention equips us to adapt to
            your evolving needs.
          </div>
        </div>
      </div>
      <div className="container mx-auto md:pt-16 md:pb-10">
        <div className="grid gap-2 px-5 bg-gray-100 md:grid-cols-2 lg:gap-8 md:rounded-xl md:shadow-lg md:shadow-gray-600 group">
          <div className="my-10 font-medium leading-loose text-justify text-black md:my-24 indent-8">
            Rooted in the "Customers for Life" ethos, RKS MOTOR PVT LTD centers
            on delivering value. Our skilled workforce, advanced technology, and
            unwavering commitment to service excellence ensure satisfaction.
            Fueled by a history of excellence and customer contentment, we stand
            ready to thrive in the dynamic business landscape. With the
            flexibility to navigate change and an unwavering commitment to
            constant innovation, we seamlessly cater to evolving needs.
          </div>
          <div className="pb-4 duration-500 lg:-mt-12 group-hover:grayscale-0 grayscale group-hover:saturate-200">
            <img
              src="https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/nexa/thumbnails/hafeezpet-outlet.webp"
              // src='https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/saboonexa/Maruti-Suzuki-Nexa_Lumbini.webp'
              className="w-full mb-6 overflow-hidden shadow-xl rounded-xl shadow-gray-600 lg:mb-10"
              alt="Maruti-Suzuki-Nexa_Lumbini"
            />
          </div>
        </div>
      </div>
      <div className="container mx-auto bg-gray-100 md:rounded-xl md:shadow-lg md:shadow-gray-600 group">
        <div className="px-4 py-5 my-10 leading-loose text-justify text-black md:py-10 md:px-5 lg:px-10 ">
          <div>
            <span className="text-2xl font-medium">Why to choose us</span>
            <br />
            Absolutely, here are ten compelling reasons to choose Saboo RKS
            Motor Pvt Ltd for your automotive needs:
          </div>
          <span className="font-medium uppercase">
            1. Legacy of Excellence:
          </span>
          <div className="px-2 pb-2 lg:px-4">
            With a rich history dating back to 1973, we boast over four decades
            of experience in the automotive industry, showcasing our enduring
            commitment to delivering quality products and services.
          </div>
          <span className="font-medium uppercase">2. Comprehensive Range:</span>
          <div className="px-2 pb-2 lg:px-4">
            Our extensive portfolio spans both two-wheelers and four-wheelers,
            providing you with a wide array of options to choose from, all under
            one roof.
          </div>
          <span className="font-medium uppercase">
            3. Maruti Suzuki Partnership:
          </span>
          <div className="px-2 pb-2 lg:px-4">
            Being an esteemed Maruti Suzuki dealer since 1997, we bring you the
            reliability and innovation of one of India's most trusted automobile
            brands.
          </div>
          <span className="font-medium uppercase">4. Expansive Network:</span>
          <div className="px-2 pb-2 lg:px-4">
            Our presence is felt across the city with five sales outlets, nine
            workshops, four pre-owned vehicle sales outlets, and two premium
            NEXA dealerships, ensuring convenience and accessibility for all
            your automotive needs.
          </div>
          <span className="font-medium uppercase">
            5. Customer-Centric Philosophy:
          </span>
          <div className="px-2 pb-2 lg:px-4">
            Embracing the "Customers for Life" philosophy, our focus is on
            building lasting relationships by providing exceptional value, not
            just in products but in every interaction.
          </div>
          <span className="font-medium uppercase">6. Skilled Workforce:</span>
          <div className="px-2 pb-2 lg:px-4">
            Our enthusiastic team of professionals is not only skilled but also
            passionate about ensuring your satisfaction. Their dedication shines
            through in every service we provide.
          </div>
          <span className="font-medium uppercase">
            7. Cutting-Edge Technology:
          </span>
          <div className="px-2 pb-2 lg:px-4">
            Equipped with the latest technological advancements and modern
            facilities, we deliver top-notch services that meet the highest
            standards of quality and precision.
          </div>
          <span className="font-medium uppercase">8. Variety of Services:</span>
          <div className="px-2 pb-2 lg:px-4">
            From sales to maintenance, our offerings are holistic. We provide
            sales of new and pre-owned vehicles, efficient workshops for
            servicing and repairs, and commercial solutions tailored to
            businesses.
          </div>
          <span className="font-medium uppercase">
            9. Customer Satisfaction:
          </span>
          <div className="px-2 pb-2 lg:px-4">
            With a proven track record of outstanding service quality and
            customer satisfaction, you can trust that your experience with us
            will be second to none.
          </div>
          <span className="font-medium uppercase">
            10. Adaptability and Innovation:
          </span>
          <div className="px-2 pb-2 lg:px-4">
            In an ever-changing business landscape, we remain agile and
            proactive in adapting to new trends and technologies, ensuring that
            we are always ahead in meeting your evolving needs.
          </div>
          In choosing Saboo RKS Motor Pvt Ltd, you're not just choosing an
          automotive dealer; you're choosing a partner that's dedicated to
          enhancing your journey, from the moment you step into our outlets to
          the moment you drive away with confidence and satisfaction.
        </div>
      </div>
    </>
  );
}

export default About;
