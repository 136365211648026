//same for home screen and offers screen
export const sliders = [
  

  {
    img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/nexa/banners/new-nexa-web-banner.webp",
    alt: "Nexa Offers: October 2023 Discounts on Maruti Suzuki Cars",
  },

  {
    img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/saboonexa/Banner/xl6_homeBanner.webp",
    alt: "Maruti Nexa Discount In October 2024- Baleno, Ciaz, Ignis, XL6, Invicto, Fronx, Jimny, Grand Vitara",
  },
  {
    img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/newage_baleno_224.webp",
    alt: "Nexa Offers: Want to buy a Nexa car this year?",
  },
  {
    img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/saboonexa/Banner/banners/ignis_slider+(1).webp",
    alt: "Maruti NEXA Cars Offered With Discounts |  https://saboonexa.in/",
  },
];

//same for home screen and offers screen
export const mobileSliders = [
  {
    img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/nexa/banners/Website_Mobile_Diwali_+Dhanteras_Saboo_RKS_Motor_Maruti_Suzuki_Nexa_Offers.webp",
    alt: "Diwali Dhanteras Saboo RKS Motor Maruti Suzuki Nexa Offers",
  },
  // {
  //   img: 'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/nexa/banners/Website+Mobile+Maruti+Suzuki+Nexa+Teachers+Day+September+Saboo+RKS+Motor.webp',
  //   alt: 'sep-01 mobile banner',
  // },
  {
    img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/nexa/invicto/invicto-mobile-banner.webp",
    alt: "invicto mobile banner",
  },
  {
    img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/nexa/fronx/webpage-fronx-mobile-banner.webp",
    alt: "NEW fronx mobile banner",
  },
  {
    img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/nexa/banners/Nexa+Service+mobile+banner.webp",
    alt: "NEW BANNER april 2023",
  },
  // {
  //   img: 'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/nexa/jimny/jimny-mobilr.webp',
  //   alt: 'jimny-mobile-banner',
  // },
  // {
  //   img: 'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/nexa/fronx/fronx-mobile-banner.webp',
  //   alt: 'fronx-mobile-banner',
  // },
  // {
  //   img: 'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/nexa/banners/nexa-cng-mobile-banner-nov1.webp',
  //   alt: 'new-nov-scng-mobile-banner',
  // },
  // {
  //   img: 'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/nexa/hafeezpet-new-banner.webp',
  //   alt: 'hafeezpet-new-banner',
  // },

  // {
  //   img: 'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/nexa/banners/new-nexa-mobile-banner.webp',
  //   alt: 'Grand Vitara',
  // },
  // {
  //   img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/saboonexa/Banner/banners/mobile_monsoon.webp",
  //   alt: "banner-4",
  // },
  // {
  //   img: 'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/saboonexa/Banner/xl6_mobile_banner.webp',
  //   alt: 'banner-1',
  // },
  // {
  //   img: 'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Mobile-Banner-Saboo-RKS-Nexa.png',
  //   alt: 'banner-2',
  // },
  // {
  //   img: 'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/saboonexa/Banner/ignis_mobile.jpg',
  //   alt: 'banner-3',
  // },
];

export const products = [

  {
    id: 9,
    name: "e-Vitara",
    price: "Coming-soon",
    logo: require('../components/Header/e vitara logo-01 webp.webp'),
    img: require('../assets/cars/Evitara.png'),
    specImage: require('../assets/cars/Evitara.png'),
    brouchure:"https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/saboonexa/Brochure/e_VITARA_Saboo_RKS_Motor_Nexa_Brochure.pdf",
   
    explore: "/nexa-evitara-on-road",
    engineType: "K15C SMART HYBRID",
    fuelType: "PETROL/CNG",
    displacement: "1462 cc",
    power: "75.8 @ 6000 rpm",
    torque: "136.8 @ 4400",
    fuelTank: "45 L",
    mileage: "20.97 (MT), 20.27 (AT)",
    tranmission: "5 MT / 6 AT",
    length: "4445",
    width: "1775",
    height: "1755",
    wheelbase: "2740",
    turningRadius: "5.2",
    frontBrake: "Disc",
    rearBrake: "Drum",
    frontSuspension: "MacPherson Strut with Coil Spring",
    rearSuspension: "Torsion Beam with Coil Spring",
    seating: "6",
  },

  


  {
    id: 1,
    name: "Invicto",
    price: "25,21,000*",
    logo: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/nexa/invicto/D23+logo+without+outline+Black_125x35.webp",
    img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/nexa/invicto/invicto-main-thubmnail-no-bg.webp",
    specImage:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/nexa/thumbnails/specification/Invicto+Spec.webp",
    brouchure:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/nexa/invicto/Saboo+RKS_Invicto.pdf",
    explore: "/maruti-invicto-price-in-hyderabad",
    engineType: "SMART HYBRID / ELECTRIC HYBRID",
    fuelType: "PETROL/HYBRID",
    displacement: "1987 cc",
    power: "112 kW @6000 rpm",
    torque: "188Nm @ 4400-5200 rpm",
    fuelTank: "52 L",
    mileage: "23.24 ",
    tranmission: "e-CVT",
    length: "4755",
    width: "1850",
    height: "1790",
    wheelbase: "2850",
    turningRadius: "5.65",
    frontBrake: "Ventilated Disc",
    rearBrake: "Solid Disc",
    frontSuspension: "MacPherson Strut",
    rearSuspension: "Torsion Beam",
    seating: "7",
  },
  {
    id: 2,
    name: "Fronx",
    price: "7,51,500*",
    logo: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/nexa/fronx/Flash_header_Menu_logo.webp",
    img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/nexa/fronx/fronx-cng-thumbnail.webp",
    specImage:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/nexa/thumbnails/specification/Fronx+Spec.webp",
    brouchure:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/nexa/fronx/FRONX+Brochure.pdf",
    explore: "/maruti-fronx-price-in-hyderabad",
    engineType: "SMART HYBRID / ELECTRIC HYBRID",
    fuelType: "PETROL/CNG",
    displacement: "1197 cc",
    power: "73.6 kW@5500rpm*",
    torque: "147.6@2000-4500*",
    fuelTank: "37 L",
    mileage: "22 ",
    // mileage: "21.11 (MT), 20.58 (AT), 19.38 (AllGrip MT), 27.97 (e-CVT)",
    tranmission: "5MT/ 6AT / e-CVT",
    length: "3995",
    width: "1765",
    height: "1550",
    wheelbase: "2520",
    turningRadius: "4.9",
    frontBrake: "Disc",
    rearBrake: "Drum",
    frontSuspension: "MacPherson Strut",
    rearSuspension: "Torsion Beam",
    seating: "5",
    weight: " 1480",
  },
  {
    id: 3,
    name: "Jimny",
    price: "12,74,000*",
    logo: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/nexa/jimny/120x30+black.webp",
    img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/nexa/thumbnails/jimny.webp",
    specImage:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/nexa/thumbnails/specification/Jimny+Spec.webp",
    brouchure:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/nexa/jimny/Jimny+Brochure.pdf",
    explore: "/maruti-jimny-price-in-hyderabad",
    engineType: "SMART HYBRID / ELECTRIC HYBRID",
    fuelType: "PETROL",
    displacement: "1462 cc",
    power: "77.1kW @ 6000 rpm [104.8PS @ 6000 rpm]",
    torque: "134.2 Nm@4000",
    fuelTank: "40 L",
    mileage: "16-17  ",
    // mileage: "16.39 kmpl - 16.94 kmpl",
    tranmission: "5MT/4AT",
    length: "3985",
    width: "1645",
    height: "1720",
    wheelbase: "2590",
    turningRadius: "5.7",
    frontBrake: " Disc",
    rearBrake: "Drum",
    frontSuspension: "3-link Rigid Axle with Coil Spring",
    rearSuspension: "3-link Rigid Axle with Coil Spring",
    seating: "4",
  },
  {
    id: 4,
    name: "Grand Vitara",
    price: "10,80,000*",
    logo: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/nexa/grand-vitara/color-icons/GV-logo-204x37+webp.webp",
    img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/nexa/grand-vitara/thumbnails/gt-thumbnail-cng.webp",
    specImage:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/nexa/thumbnails/specification/GV+Spec.webp",
    brouchure:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/saboonexa/Brochure/grand_vitara.pdf",
    explore: "/grand-vitara-on-road-price-in-hyderabad",
    engineType: "SMART HYBRID / ELECTRIC HYBRID",
    fuelType: "PETROL/CNG",
    displacement: "1462 cc",
    power: "75.8 @ 6000 / 68 kW @ 5500 rpm",
    torque: "136.8 @ 4400 / 122 Nm @ 4400 - 4800",
    fuelTank: "45 L",
    mileage: "21 ",
    tranmission: "5MT/ 6AT / e-CVT",
    length: "4345",
    width: "1795",
    height: "1645",
    wheelbase: "2600",
    turningRadius: "5.4",
    frontBrake: "Ventilated Disc",
    rearBrake: "Solid Disc",
    frontSuspension: "MacPherson Strut",
    rearSuspension: "Torsion Beam",
    seating: "5",
  },

  {
    id: 5,
    name: "Ignis",
    price: "5,84,000*",
    logo: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/saboonexa/logos/Maruti-Suzuki-Ignis-logo.webp",
    img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/saboonexa/Banner/Product-thumbnail/Saboo-Nexa-product-Ignis.webp",
    specImage:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/nexa/thumbnails/specification/Ignis+Spec.webp",
    brouchure:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/saboonexa/Brochure/Maruti-Suzuki-Ignis-Broucher.pdf",
    explore: "/nexa-ignis-on-road-price-in-hyderabad",
    engineType: "VVT",
    fuelType: "PETROL",
    displacement: "1179 cc",
    power: "61 @ 6000 rpm",
    torque: "113 @ 4200",
    fuelTank: "32 L",
    mileage: "20.89 ",
    tranmission: "5 MT / AMT",
    length: "3700",
    width: "1690",
    height: "1595",
    wheelbase: "2435",
    turningRadius: "4.7 m",
    frontBrake: "Disc",
    rearBrake: "Drum",
    frontSuspension: "MacPherson Strut",
    rearSuspension: "Torsion Beam",
    seating: "5",
  },
  {
    id: 6,
    name: "Baleno",
    price: "6,66,000*",
    logo: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/newage_baleno_logo.png",
    img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/nexa/cng/baleno-cng-thubnail.webp",
    specImage:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/nexa/thumbnails/specification/Baleno+Spec.webp",
    brouchure:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/The_New_Age_Baleno_Brochure.pdf",
    explore: "/new-maruti-baleno-price-in-hyderabad",
    engineType: "Adv. K Series Dual Jet / Dual VVT Engine",
    fuelType: "PETROL/CNG",
    displacement: "1197 cc",
    power: "66 KW @ 6000 rpm",
    torque: "113 @ 4400",
    fuelTank: "37 L",
    mileage: "22.35 (MT), 22.94 (AMT)",
    tranmission: "5MT/5AT",
    length: "3990",
    width: "1745",
    height: "1500",
    wheelbase: "2520",
    turningRadius: "4.85",
    frontBrake: "Disc",
    rearBrake: "Drum",
    frontSuspension: "MacPherson Strut",
    rearSuspension: "Torsion Beam",
    seating: "5",
  },
  {
    id: 7,
    name: "Ciaz",
    price: "9,40,000*",
    logo: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/saboonexa/logos/Saboo-Nexa-Ciaz-logo.webp",
    img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/saboonexa/Banner/Product-thumbnail/Saboo-Nexa-product-ciaz.webp",
    specImage:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/nexa/thumbnails/specification/Ciaz+Spec.webp",
    brouchure:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/saboonexa/Brochure/Maruti-Suzuki-All_new_Ciaz-Brochure.pdf",
    explore: "/nexa-ciaz-on-road-price-in-hyderabad",
    engineType: "K15 Smart Hybrid Petrol Engine",
    fuelType: "PETROL",
    displacement: "1462 cc",
    power: "77 KW @ 6000 rpm",
    torque: "136.8 @ 4400",
    fuelTank: "43 L",
    mileage: "20.65 (MT) / 20.04 (AT)",
    tranmission: "5MT/4AT",
    length: "4490",
    width: "1730",
    height: "1485",
    wheelbase: "2650",
    turningRadius: "5.4",
    frontBrake: "Ventilated Disc",
    rearBrake: "Drum",
    frontSuspension: "MacPherson ",
    rearSuspension: "Torsion Beam",
    seating: "5",
  },

  {
    id: 8,
    name: "XL6",
    price: "11,61,000*",
    logo: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/saboonexa/logos/Saboo-Nexa-Logo-XL6.webp",
    img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/nexa/cng/xl6-cng-thumbnail.webp",
    specImage:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/nexa/thumbnails/specification/XL6+Spec.webp",
    brouchure:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/saboonexa/Brochure/THE+ALL-NEW+XL6+Brochure.pdf",
    explore: "/nexa-xl6-on-road-price-in-hyderabad",
    engineType: "K15C SMART HYBRID",
    fuelType: "PETROL/CNG",
    displacement: "1462 cc",
    power: "75.8 @ 6000 rpm",
    torque: "136.8 @ 4400",
    fuelTank: "45 L",
    mileage: "20.97 (MT), 20.27 (AT)",
    tranmission: "5 MT / 6 AT",
    length: "4445",
    width: "1775",
    height: "1755",
    wheelbase: "2740",
    turningRadius: "5.2",
    frontBrake: "Disc",
    rearBrake: "Drum",
    frontSuspension: "MacPherson Strut with Coil Spring",
    rearSuspension: "Torsion Beam with Coil Spring",
    seating: "6",
  },



 


  





  // {
  //   name: "Suzuki Connect",
  //   price: "10140",
  //   logo: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/saboonexa/logos/Maruti-Suzuki-Connect-Logo.webp",
  //   img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/saboonexa/Banner/Product-thumbnail/Saboo-Nexa-product-suzuki-connect.webp",
  //   brouchure: "#",
  //   explore: "/",
  //   bookNow: "/",
  // },
];

export const offer_products = [
  {
    name: "Grand Vitara",
    price: "1,13,100",
    desc: "+ EW + 20,000 Upgrade bonus",
    logo: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/nexa/grand-vitara/color-icons/GV-logo-204x37+webp.webp",
    img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/nexa/grand-vitara/thumbnails/gt-thumbnail-cng.webp",
    explore: "/grand-vitara-on-road-price-in-hyderabad",
  },
  {
    name: "Ignis",
    price: " 58,100",
    // desc: "",
    logo: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/saboonexa/logos/Maruti-Suzuki-Ignis-logo.webp",
    img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/saboonexa/Banner/Product-thumbnail/Saboo-Nexa-product-Ignis.webp",
    explore: "/nexa-ignis-on-road-price-in-hyderabad",
  },
  {
    name: "Jimny",
    price: " 1,00,000",
    // price: "₹ coming soon",
    logo: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/nexa/jimny/120x30+black.webp",
    img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/nexa/thumbnails/jimny.webp",
    explore: "/maruti-jimny-price-in-hyderabad",
   // desc: "Special finance offer worth ₹ 1,50,000",
  },

  {
    name: "Baleno",
    price: "57,100",
   // desc: "Regal Kit worth Rs.60,199",
    logo: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/newage_baleno_logo.png",
    img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/nexa/cng/baleno-cng-thubnail.webp",
    explore: "/new-maruti-baleno-price-in-hyderabad",
  },

  {
    name: "Ciaz",
    price: "45,000",
    logo: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/saboonexa/logos/Saboo-Nexa-Ciaz-logo.webp",
    img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/saboonexa/Banner/Product-thumbnail/Saboo-Nexa-product-ciaz.webp",
    explore: "/nexa-ciaz-on-road-price-in-hyderabad",
  },
  {
    name: "XL6",
    price: "20,000",
    logo: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/saboonexa/logos/Saboo-Nexa-Logo-XL6.webp",
    img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/nexa/cng/xl6-cng-thumbnail.webp",
    explore: "/nexa-xl6-on-road-price-in-hyderabad",
  },
  {
    name: "Fronx",
    price: "45,000",
   desc: "Free Velocity Kit (Worth 43K)",
    logo: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/nexa/fronx/Flash_header_Menu_logo.webp",
    img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/nexa/fronx/fronx-cng-thumbnail.webp",
    explore: "/maruti-fronx-price-in-hyderabad",
  },
   {
     name: "Invicto ",
    price: "1,25,000",
   //  desc: "Special finance offer worth",
     logo: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/nexa/invicto/D23+logo+without+outline+Black_125x35.webp",
     img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/nexa/invicto/invicto-main-thubmnail-no-bg.webp",
     explore: "/maruti-invicto-price-in-hyderabad",
   },
];

export const blogs = [
  {
    title: "Lakme Fashion week 2020 digital fluid edition",
    text: "Maruti opens its premium dealerships, Nexa in Hyderabad. Maruti Suzuki has entered the premium auto market with the launch of NEXA showrooms. The new edition to the brand’s latest philosophy was started by dealers, Saboo RKS  at Banjara Hills and Jubilee Hills, respectively.",
    img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/saboonexa/blog/Saboo-Nexa-SCross-Launch.webp",
    href: "https://www.thehansindia.com/posts/index/Auto-/2015-08-01/Maruti-opens-its-premium-dealerships-Nexa-in-Hyderabad/167139?infinitescroll=1",
  },
  {
    title: "Gaadi Delivered",
    text: "Post the launch, RKS Saboo delivered its first Maruti Suzuki Baleno to customer G Radhika. The key was handed over by Sandeep Kumar Sultania, IAS Transport Commissioner and CMD Vinay Saboo at Nexa Jubilee showroom of Saboo RKS group.",
    img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/saboonexa/blog/Saboo-Nexa-Car-Delivery.webp",
    href: "https://www.thehansindia.com/posts/index/Auto-/2015-08-01/Maruti-opens-its-premium-dealerships-Nexa-in-Hyderabad/167139?infinitescroll=1",
  },
  {
    title: "Nexa Lifestyle – A Journey Through The Finer Things In Life",
    text: "At NEXA, we are always on a quest to deliver new and inspiring experiences.",
    img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/saboonexa/blog/Maruti-Suzuki-SCross-Images.webp",
    href: "#",
  },
  {
    title: "Nexa Experince: Some Inspiring Recent Affairs",
    text: "Looking back at all the recently curated premium experiences.",
    img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/saboonexa/blog/Maruti-Suzuki-SCross-Launch.webp",
    href: "#",
  },
  // {
  //   title: "Nexa Lifestyle - A Journey Through the finer things in life",
  //   text: "Our drive to provide you with inspiring moments has led us to create experiences that are beyond just automobiles.",
  //   img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/saboonexa/blog/Saboo-Nexa-SCross-Launch.webp",
  //   href: "https://www.thehansindia.com/posts/index/Auto-/2015-08-01/Maruti-opens-its-premium-dealerships-Nexa-in-Hyderabad/167139?infinitescroll=1",
  // },
];

export const testimonials = [
  {
    text: `Great experience buying my new XL6. It all started with the praveen one of the RM making a call about my interest followed by Mr. Abishek's cordial and informative interactions that made me go with RKs`,
    author: "Rohin Kumar",
  },
  {
    text: `Taken delivery from RKS motor smooth delivery process by Mr .Abhishek and rajashekar I recommend every to purchase from nexa Jubilee thank you team`,
    author: "Bhanu",
  },
  {
    text: `Great receive, good staff good service`,
    author: "HARI PRASAD RAO",
  },
  {
    text: `Thanks to surya sir, shanth sir, patel sir, kiran finance rajashekar, abhishek for the quick delivery of  my ignis zeta automatic  7 star to nexa jubilee`,
    author: "Saumil Mehta",
  },
  {
    text: `Good buying experience`,
    author: "SPAR TECHNOLOGIES TECHNOLOGY",
  },
  {
    text: `Mr R M Shashikant & manager Mr Shrinivas has been very friendly & helpful`,
    author: "Nick Bhatia",
  },
  {
    text: `Nice showroom for taking delivery.`,
    author: "Jai Kanjani",
  },
  {
    text: `Great experience.  Got my car ahead of promised date. Veeresh has provided excellent service.`,
    author: "Sushim Shrivastava",
  },
  {
    text: `Delivery experience was excellent..srikanth RM has given me the best service. I would recommend my friends to purchase the car with nexa jubilee`,
    author: "Sai Nath Chowdhury",
  },
  {
    text: `Rm praneeth SRM srinivas where very good communication good service thank you team rks and srinivas praneeth`,
    author: "Ravi Kumar",
  },
  {
    text: `Thanks for supporting Bhavya and team`,
    author: "Arun Kumar",
  },
  {
    text: `Excellent service given by Ravi Teja(RM)`,
    author: "anil kumar",
  },
  {
    text: `Excellent service by Prashanth Rks nexa`,
    author: "Mohammed azam",
  },
  {
    text: `Rm Praneeth srm srinivas made my car delivery memorable good service and communication thank you  team rks and Praneeth and srinivas`,
    author: "Syued Nayab",
  },
  {
    text: `Thank you Mr Ravi making me to choose right vehicle. Excellent service given by Ravi`,
    author: "babu rao",
  },
  {
    text: `Sreekanth has given excellent service to me. He is very helpful to choose a right vehicle.`,
    author: "ravi vangala",
  },
  {
    text: `Have good experience here with Pradeep`,
    author: "Asrith Reddy",
  },
  {
    text: `Excellent hospitality and great help from Madan and Rajesh.
    Overall experience was good and hassle free`,
    author: "Jagdish Kumar",
  },
  {
    text: `Nice staff.rahul was good at presentation`,
    author: "dr.kotha rajeshwari",
  },
  {
    text: `Farooq Mirza is excellent person to deal with ,he helped us a lot in selecting the right variant ,he is good person`,
    author: "ritesh agarwal",
  },
  {
    text: `Pradeep Bandari is very courteous, prompt, sincere and helpful in suggesting what we need.`,
    author: "budidha srinivas reddy",
  },
  {
    text: `Allhamdullilah satisfied with nexa service it was so premium n relationship manager was too good at explanation n I'm satisfied with the service of pradeep bandari relationship manager`,
    author: "Aquib Baig",
  },
  {
    text: `Nice Experience At nexa lumbini Stongly Recommend Nexa Lumbini And osman Relationship Manager`,
    author: "Mohammed Meraj",
  },
  {
    text: `Amazing experience of purchasing new car. Every one was extremely cordial and customer focused.
    Thank you very much Mr srihari & Imran sir`,
    author: "Uday Ratnalikar",
  },
  {
    text: `Mr Osman has given excellent service.`,
    author: "Uthup Joseph ",
  },
  {
    text: `Excellent service provided by manjunath swamy`,
    author: "Murali T",
  },
  {
    text: `Excellent service..great experience thankyou team NEXA and Mr. Usman`,
    author: "Zuhair Khan",
  },
  {
    text: `Excellent dealer to purchase the vehicle.Appreciated the work of sathya who made it possible in delivering the vehicle in time.Perfect place to purchase ur vehicle .
    Thank you RKS SABOO LUMBINI.`,
    author: "sai kishore vulse",
  },
  {
    text: `Mohan Krishna sales person very responsive and supported from booking to vehicle delivery.`,
    author: "mstd",
  },
  {
    text: `I would like to thanks Mohan Anna and team`,
    author: "shaik mansoor",
  },
  {
    text: `Excellent work done by Rm pradeep and well answered all the queries.`,
    author: "Gopal Ladha",
  },
  {
    text: `Excellent service. Very quick turnaround. Polite and concerned service advisor. Special mention to B Sai Bharath, he did a fabulous job.`,
    author: "Sushim Shrivastava",
  },
  {
    text: `Excellent customer service`,
    author: "Srikar Prasad",
  },
  {
    text: `Excellent service`,
    author: "Venna Mahendra",
  },
  {
    text: `Best service quality fast service special thanks to shaik naseem basha for his full corporation help & support and able to facilitate with his best management skills.`,
    author: "Satyam Arun",
  },
  {
    text: `Experienced technician and good knowledge service advisers.`,
    author: "maruti electronics",
  },
  {
    text: `Excellent customer care`,
    author: "Kappara Snmurthy",
  },
  {
    text: `Good experience`,
    author: "Ashu Basme",
  },
  {
    text: `Manager Mr.Naveen showcased utmost professionalism in servicing my car, making it appear as good as new. The service provided at RKS Nexa Hafeezpet was excellent.`,
    author: "kalyan sheelam",
  },
  {
    text: `Great experience @ Nexa Service. Body shop manager Mr. Basha is simply outstanding. He made the insurance process hassle free and delivered my car very fast.`,
    author: "Prateek Srivastava",
  },
  {
    text: `This is the best service center i have seen still now. Best service and best staff.`,
    author: "jyothsna inkollu",
  },
  {
    text: `exlent service`,
    author: "Anil Nelamancha",
  },
  {
    text: `Excellent service 💯,`,
    author: "Renuka Unnamatla",
  },
  {
    text: `Good service`,
    author: "Syed Irfan",
  },
  {
    text: `Excellent service`,
    author: "Bairi Sruthi",
  },
  {
    text: `Nice experience`,
    author: "Vamsi Krishna",
  },
  {
    text: `Overall service is excellent, staff behaviour and response is very nice.
    `,
    author: "Babitha Babitha",
  },
  {
    text: `I have received excellent receiving from MR.Rajesh and Madan and they are very Co operative and friendly. During my purchase my experience is excellent.`,
    author: "SITA RAMA MURTHY",
  },
  {
    text: `Very good`,
    author: "Intelligence mto",
  },
  {
    text: `Relationship Manger sri hari has given best`,
    author: "chamakura jayanth",
  },
  {
    text: `Had a good experience buying a car from this show room. I have convey my thanks to Kishore Vootkuri for his time and prompt response. He helped me with all the proceedings in the show room and delivered the vehicle before the promised date. Thanks Kishore and anila for their courtesy throughout and showroom too.`,
    author: "Dr Vishnu Chaitanya",
  },
  {
    text: `Overall very good satisfactory experience with sales person-Avinash from rks nexa lumbini`,
    author: "Raees Shaik",
  },
  {
    text: `Excellent service given by srikanth`,
    author: "Mallu Mallesh",
  },
  {
    text: `Excellent service, appreciate. Thank you . Thank you Mr. zulfi`,
    author: "Mohammed Shoeb ahmed",
  },
  {
    text: `Good service
    Mr.srinivas`,
    author: "sangavi rayabarapu",
  },
  {
    text: `It's very nice show room and good marketing persons well trained PPL.`,
    author: "natraj telukuntla",
  },
  {
    text: `Excellent Service By Praneeth`,
    author: "Manoj Koppu",
  },
  {
    text: `Very good delivery experience .xcellent service.`,
    author: "Jadhav Satish",
  },
  {
    text: `Best showroom ambience super hospitality mr Anand was very polite and explained well`,
    author: "Home ",
  },
  {
    text: `Showroom ambience was very nice mr Anand was very nice`,
    author: "Sheshank",
  },
  {
    text: `Best showroom in Hyderabad yesterday I visited Mr and explained very good and mr Raju Reddy was genius`,
    author: "Rohit Kumar",
  },
  {
    text: `Recently came for fronx enquiry staff was very polite and hospitality was very nice Mr Anand explained very well`,
    author: "Piddishetty Srikanth",
  },
  {
    text: `Well maintained showroom nice ambience mr Anand explained well`,
    author: "Venkatesh Krishnaveni",
  },
  {
    text: `Overall experience was great. I have visited RKS Motors The executive Vamshi krishna had done is job well because of him everything was easy & Smooth.`,
    author: "pavani konapala",
  },
  {
    text: `Overall experience was great executive vamshi was excellent`,
    author: "adelly rakesh",
  },
  // {
  //   text: "Hi Krishna , It was really a great experience at SABOO RKS NEXA. I would also like to appreciate you for your follow up and delivering my Car on time. You are really an asset to the company. Keep up the Good Work!!!. ",
  //   author: "Vijay Thigulla",
  // },
  // {
  //   text: "Dear Lumbini Nexa team, It was good experience at your Showroom when we came for taking delivery of S Cross vehicle. Also I would like to say that right from booking of the vehicle till delivery the team response was fantastic I specially thank Mr. Venkat , Mr. Shravan and Mr. Sunil for their commitment to deliver the vehicle as per the schedule. Once again I thank team Lumbini and wish them all the best.",
  //   author: "K S N Reddy",
  // },
  // {
  //   text: "Dear Satish, Thank you. I would like to extend my heartfelt thankfulness to you and Nexa Jubilee for giving such a great customer experience. In the present busy work life of people, one would expect a single point and end to end service in terms of purchasing a car. This was fulfilled by you and your showroom. Also, special thanks to Satish for ensuring i get the vehicle on a early delivery date i have requested. Aso, really appreciate the follow up in terms of getting the finance done quickly and without any hassles. It was a pleasure doing business with you and would definitely like to continue this going forward. Would surely recommend you and your dealer to my circle where i see a potential. Thanks once again. Regards, Raviteja / Gouri lakshmi",
  //   author: "Ravi Teja",
  // },
  // {
  //   text: "Hi Lateef, I really appriciate you on the dedication towards your work, and also suggesting me the best car variant. Once again thank you for all the hospitality and service. Regards, Srirag Reddy. E ",
  //   author: "Srirag Reddy. E",
  // },
  // {
  //   text: "First of all I would like to thank Mr. Satish. E for his good job... He have given an excellent service to us even though we went to the showroom on Sunday ... We are really satisfied with the car price, pleasing mentality and subject grip about the product... He really did a fantastic job ... With his service he didn't given any opportunity to us to check with any other showroom regarding the vehicle... He only took all the risks for loan process and getting the approval and delivering the vehicle with all commitments what he promised... Also we are very happy to refer your showroom to all of my colleagues... Thank you Mr. Satish. E for your excellent service and response and also the commitment... Regards, K. Praveen Kumar",
  //   author: "K. Praveen Kumar",
  // },
  // {
  //   text: "It was a fabulous experience with you. Thanks a lot for the support provided by you, on time delivery, info about vehicle & Discounts. Good to have a executive like you. All the best for your future endeavour. Regards Jhansi Priya",
  //   author: "Jhansi Priya",
  // },
  // {
  //   text: "Dear Nexa Team, Thank you for looking after me so well. I'm pleased with the service and hospitality you have offered. From starting to end it was an amazing journey. Thanks to all specially Lateef and Lokesh for so kind and expert on explaining each and everything in detail. I hope you will keep the same service for looking after my car with time to time service. Regards, Baseer Abdul",
  //   author: "Baseer Abdul",
  // },
];

export const showrooms = [
  {
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3807.09068763087!2d78.47048661487662!3d17.407434988066605!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb975f1cbb280d%3A0x167869bcabc7428b!2sNEXA!5e0!3m2!1sen!2sin!4v1609824802599!5m2!1sen!2sin",
    title: "Lumbini",
    address:
      "5-9-22/1/B, Shapoorji Towers, Shapoowadi, Adarsh Nagar, Hyderabad, Telangana, 500004",
  },
  {
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.4235404993383!2d78.39629631487708!3d17.43943088804738!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb99bd1d8a6561%3A0x1cb759ff5f828d3!2sNEXA!5e0!3m2!1sen!2sin!4v1609824736256!5m2!1sen!2sin",
    title: "Jubilee",
    address:
      "1-65/5, 6,15 & 16, KKR Square, Phase I, Kavuri Hills, Madhapur, Jubliee Hills (Post), Sri Rama Colony,Hyderabad, Telangana, 500033",
  },
  {
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3805.1900522157152!2d78.46161855072641!3d17.498438804139067!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb913447d392c9%3A0xb4d82d61c40f4a40!2sNexa%20(Kompally)!5e0!3m2!1sen!2sin!4v1618206357627!5m2!1sen!2sin",
    title: "Kompally",
    address: "Sy No.42, Pet-Basheerabad, Quthbullapur, Telangana 500055",
  },
];

export const workshops = [
  {
    googleMap: "https://maps.app.goo.gl/LTTXsKaExxDoiMVi8",
    address: "   HAFEEZPET",
    image:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/nexa/thumbnails/hafeezpet-outlet.webp",
  },

  {
    googleMap: "https://www.google.com/maps/place/Nexa+Service+(Saboo+RKS+Motor,Kompally)/@17.4150348,78.3917255,12z/data=!4m6!3m5!1s0x3bcb9b0050416a8d:0xe39be48925b874ef!8m2!3d17.5143238!4d78.4809953!16s%2Fg%2F11wv6xqs76?entry=ttu&g_ep=EgoyMDI1MDEyMC4wIKXMDSoASAFQAw%3D%3D",
    address: " Suchitra",
    image:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/nexa/Saboo+RKS+Motor+Nexa+Service+Kompally.webp",
  },
  {
    googleMap: "https://maps.app.goo.gl/b6CeHHSvYRSrRJc66",
    address: " Kompally",
    image:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/showroom+locations/webp-showrooms/webp-showroom/Nexa+Service.webp",
  },
  {
    googleMap:
      "https://www.google.com/maps/place/Maruti+Suzuki+Arena+-+Saboo+RKS+Motor+Pvt.+Ltd/@17.424164,78.457337,15z/data=!4m6!3m5!1s0x3bcb974b6feb9ea5:0x1e0a3e3dabcb8701!8m2!3d17.4241643!4d78.4573371!16s%2Fg%2F1tc_zym2?hl=en&entry=ttu",
    address: "  Somajiguda",
    image:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/showroom+locations/webp-showrooms/webp-service/RKS-Motor-Maruti-Suzuki-Service-Center-Near-Me-Somajiguda.webp",
  },
  {
    googleMap:
      "https://www.google.com/maps/place/Saboo+RKS+-+Maruti+Suzuki+Car+Dealer+(+Service+Center,+Moosarambagh)/@17.384806,78.507173,13z/data=!4m6!3m5!1s0x3bcb992fc2460627:0x6eec84cd9e149fab!8m2!3d17.3848063!4d78.5071734!16s%2Fg%2F11jzcb46x8?hl=en&entry=ttu",
    address: " Moosarambagh",
    image:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/showroom+locations/webp-showrooms/webp-service/RKS-Motor-Maruti-Suzuki-Nexa-Service-Center-Near-Me-Malakpet-Moosarambagh.webp",
  },
  {
    googleMap: "https://maps.app.goo.gl/Ek1xShY44fnQHpUS9",
    address: "   KODANGAL",
    image:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/showroom+locations/webp-showrooms/webp-showroom/RKS-Motor-Maruti-Suzuki-Arena-Center-Near-Me-Kodangal.webp",
  },

  {
    googleMap:
      "https://www.google.com/maps/place/Rks+Motors+Pvt.+Ltd.+-+Maruti+Service/@17.39843,78.554267,15z/data=!4m6!3m5!1s0x3bcb993aa273c193:0x31688c76343b7377!8m2!3d17.3984304!4d78.5542668!16s%2Fg%2F11pqcp886k?hl=en&entry=ttu",
    address: " Uppal",
    image:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/showroom+locations/webp-showrooms/webp-service/RKS-Motor-Maruti-Suzuki-Nexa-Service-Center-Near-Me-Uppal.webp",
  },
  {
    googleMap:
      "https://www.google.com/maps/place/Saboo+RKS+Motor+-+Maruti+Suzuki+Car+Dealer+(Service+Center,+Tadbund,+Secunderabad)/@17.451964,78.486607,15z/data=!4m6!3m5!1s0x3bcb9b59fcccc4c3:0x94b782fa83589a15!8m2!3d17.4519643!4d78.486607!16s%2Fg%2F11h_sz_f3h?hl=en&entry=ttu",
    address: " Tadbund",
    image:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/showroom+locations/webp-showrooms/webp-service/RKS-Motor-Maruti-Suzuki-Service-Center-Near-Me-Tadbund.webp",
  },
  {
    googleMap:
      "https://www.google.com/maps/place/Saboo+RKS+Motor+Nampally+-+Maruti+Suzuki+Car+Dealer(NEXA,+ARENA,+Service+Center,+TrueValue+%26+Driving+School+)/@17.393985,78.46998,15z/data=!4m6!3m5!1s0x3bcb976366ed87d9:0x7cb0979b572d6610!8m2!3d17.3939849!4d78.4699801!16s%2Fg%2F11c1qryf39?hl=en&entry=ttu",
    address: " Nampally",
    image:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/showroom+locations/webp-showrooms/webp-service/RKS-Motor-Maruti-Suzuki-Service-Center-Near-Me-Nampally.webp",
  },
  {
    googleMap:
      "https://www.google.com/maps/place/Saboo+RKS+Motor+-+Maruti+Suzuki+Car+Dealer+(Kushaiguda,+Secunderabad)/@17.476452,78.573193,15z/data=!4m6!3m5!1s0x3bcb9c7c4829c807:0xe33d0f61aa143664!8m2!3d17.4764521!4d78.5731928!16s%2Fg%2F11gbffbwcn?hl=en&entry=ttu",
    address: " Kushaiguda",
    image:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/showroom+locations/webp-showrooms/webp-service/RKS-Motor-Maruti-Suzuki-Nexa-Service-Center-Near-Me-Kushaiguda.webp",
  },
  {
    googleMap:
      "https://www.google.com/maps/place/Saboo+RKS+Motor/@17.480811,78.421741,15z/data=!4m6!3m5!1s0x3bcb91ae48fe6963:0xbbc59173810f4bd8!8m2!3d17.480811!4d78.4217405!16s%2Fg%2F124yq1dsg?hl=en&entry=ttu",
    address: " Kukatpally",
    image:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/showroom+locations/webp-showrooms/webp-service/Kukatpally.webp",
  },
  {
    googleMap:
      "https://www.google.com/maps/place/Saboo+RKS+Motor+-+Maruti+Suzuki+Sales+%26+Service+(Shamirpet,+Secunderabad)/@17.627171,78.569861,15z/data=!4m6!3m5!1s0x3bcb81bc18d30c3b:0x5f74a496d6d6fd97!8m2!3d17.627171!4d78.5698606!16s%2Fg%2F11nx66bdfc?hl=en&entry=ttu",
    address: " Shamirpet",
    image:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/showroom+locations/webp-showrooms/webp-service/RKS-Motor-Maruti-Suzuki-Service-Center-Near-Me-Shamirpet.webp",
  },
];

export const truevalue = [
  {
    googleMap: "https://maps.app.goo.gl/ZBuzK1rnchNDXRwd9",
    title: "Saboo True Value - Somajiguda",
    image:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/showroom+locations/webp-showrooms/webp-truevalue/RKS-Motor-Maruti-Suzuki-True-Value-Used-Cars-Center-Near-Me-Kushaiguda.webp",
    address:
      "RKS Motor, 6-3-905, Saboo Towers Raj Bhavan Road , Somajiguda- Beside Yashoda Hospital ,Hyderabad, Telangana, 500082",
  },
  {
    googleMap: "https://maps.app.goo.gl/3wo1SoXWUcqweG8T9",
    title: "Saboo True Value - Malakpet",
    image:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/showroom+locations/webp-showrooms/webp-truevalue/RKS-Motor-Maruti-Suzuki-True-Value-Used-Cars-Center-Near-Me-Kushaiguda.webp",
    address:
      "RKS Motor, 16-11-19/S/C/2 Salem Nagar Colony, Malakpet ,Hyderabad, Telangana, 500036",
  },
  {
    googleMap: "https://maps.app.goo.gl/bg43EvhvLmPELHRq8",
    title: "Saboo True Value - Kompally",
    image:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/showroom+locations/webp-showrooms/webp-truevalue/RKS-Motor-Maruti-Suzuki-True-Value-Used-Cars-Center-Near-Me-Kushaiguda.webp",
    address:
      "RKS Motor, Sy No.42, Pet-Basheerabad, Quthbullapur,Hyderabad, Telangana, 500055",
  },

  {
    googleMap: "https://maps.app.goo.gl/xbZNBxWqe11CZ6yR8",
    title: "Saboo True Value - Kushaiguda",
    image:
      "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/showroom+locations/webp-showrooms/webp-truevalue/RKS-Motor-Maruti-Suzuki-True-Value-Used-Cars-Center-Near-Me-Kushaiguda.webp",
    address:
      "RKS Motor, 1-10-1/285/1, Opp.Old Vegetable Market, ECIL X Road , Kushaiguda ,Hyderabad, Telangana, 500 062",
  },
];

export const accsliders = [
  // {
  //   img: require("../assets/HomePageImages/Maruti_Nexa_Accessories_Summer_cool_offers_Saboo_RKS_Nexa_Banner-02.webp"),
  //   alt: "Nexa Accessories banner",
  // },
  {
    img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/accessories/INVICTO-Banner_Desktop.webp",
    alt: "invicto banner",
  },
  {
    img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/accessories/Jimny+_Desktop_1500x470.webp",
    alt: "jimny banner",
  },
  {
    img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/accessories/Fronx+webp.webp",
    alt: "fronx banner",
  },
  {
    img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/accessories/GrandVitara_Desktop_1500x470.webp",
    alt: "grand vitara banner",
  },
  {
    img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/accessories/XL6-Launch_Desktop.webp",
    alt: "xl-6 banner",
  },
  {
    img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/accessories/NEXA+Kit+Desktop+banner+-+1500+x+470.webp",
    alt: "banner-5",
  },
  // {
  //   img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/saboonexa/nexa-accessories/Saboo-Maruti-Suzuk-Nexa-Accessories.webp",
  //   alt: "banner-7",
  // },
  // {
];

export const accgal = [
  {
    img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/saboonexa/nexa-accessories/Saboo-Maruti-Suzuk-Nexa-Car-Wheel.webp",
    alt: "banner-1",
  },
  {
    img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/saboonexa/nexa-accessories/Saboo-Maruti-Suzuk-Nexa-Accessories-Seating.webp",
    alt: "banner-2",
  },
  {
    img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/saboonexa/nexa-accessories/Saboo-Maruti-Suzuk-Nexa-Genuine-Accessories-Kit.webp",
    alt: "banner-3",
  },
  {
    img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/saboonexa/nexa-accessories/Saboo-Maruti-Suzuk-Nexa-Genuine-Accessories-Price-List.webp",
    alt: "banner-4",
  },
  {
    img: "https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/saboonexa/nexa-accessories/Saboo-Maruti-Suzuk-Nexa-Genuine-Accessories-Car-Perfume.webp",
    alt: "banner-5",
  },
];

export const categoriess = [
  {
    filedName: "Exteriors",
    data: [
      {
        item: "Premium Bi-Cycle Stand  | Jimny",
        img: "https://marutistoragenew1.azureedge.net/paccmicroservice/9917B-78R01/9917b-78r01_0.jpg",
        code: "9917B-78R01",
        price: "15990",
      },
      {
        item: "Premium Roof Bars | Jimny",
        img: "https://marutistoragenew1.azureedge.net/paccmicroservice/99176-78R11/99176-78r11_0.jpg",
        code: "99176-78R11",
        price: "16785",
      },
      {
        item: "Premium Roof Basket | Jimny",
        img: "https://marutistoragenew1.azureedge.net/paccmicroservice/99177-78R01/99177-78r00_0.jpg",
        code: "99177-78R01",
        price: "23590",
      },
      {
        item: "Mud Flap | Grand Vitara",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/05/72201m76tx0_1.jpg",
        code: "72201M76TX0",
        price: "650",
      },
      {
        item: "Rear Spoiler Extender - Black | Grand Vitara",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/17/990j0m76t07-070_0.jpg",
        code: "990J0M76T07-070",
        price: "590",
      },
      {
        item: "Bumper  Protector | New Baleno",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/05/25/990j0m99917-200_1-jpg.jpg",
        code: "990J0M99917-200",
        price: "1490",
      },
      
      {
        item: "Mud Flap | Fronx",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/07/72201m74t00_1.jpg",
        code: "72201M74T00",
        price: "590",
      },
      {
        item: "Wheel Cover (15) | Fronx",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/07/43250m74t00-zmq_2.jpg",
        code: "43250M74T00-ZMQ",
        price: "590",
      },
      {
        item: "MudFlap Set | New Baleno",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/02/72201m55t00_1.jpg",
        code: "72201M55T00",
        price: "690",
      },
      {
        item: "Mud Flap | Jimny",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/10/72201m80t00_0.jpg",
        code: "72201M80T00",
        price: "899",
      },
      {
        item: "Rear Upper Spoiler Extender - Black + Red | Fronx",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2021/03/0203/990j0m56r07-190_1.jpg",
        code: "990J0M56R07-190",
        price: "1300",
      },
      {
        item: "Front Underbody Spoiler - Grey  | Fronx",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2021/03/0203/990j0m72r07-060_1.jpg",
        code: "990J0M72R07-060",
        price: "9,390",
      },
      {
        item: "Rear Parcel Tray  | New Baleno",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/05/88910m55t00-r3f_1-jpg.jpg",
        code: "88910M55T00-R3F",
        price: "1120",
      },
      {
        item: "Anti Skid Mat - Small (Black) | New Baleno",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/r13/990j0m999h2-900_1.jpg",
        code: "990J0M999H2-900",
        price: "119",
      },
      {
        item: "Door Visor  | New Baleno",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/04/baleno/990j0m55tq0-010_0-png.png",
        code: "990J0M55TQ0-010",
        price: "1590",
      },
      {
        item: "Upper Grill Garnish Finish | New Baleno",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/04/baleno/990j0m55t13-010_0-jpg.jpg",
        code: "990J0M55T13-010",
        price: "1050",
      },
      {
        item: "Door Visor - with Chrome Insert | Ciaz",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/r5/990j0m79mq0-030_1.jpg",
        code: "990J0M79MQ0-030",
        price: "2750",
      },
      {
        item: "Back Door Garnish (Chrome) | Ciaz",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q29/990j0m79m13-040_1.jpg",
        code: "990J0M79M13-040",
        price: "990",
      },
      {
        item: "Rear Glass Spoiler (Snow White) | Ciaz",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/r5/990j0m79mq0-050_1.jpg",
        code: "990J0M79MQ0-050",
        price: "2490",
      },
      // {
      //   item: "Rear Underbody Spoiler - Grey + Red | Fronx",
      //   img: "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/07/990j0m74t07-110_0.jpg",
      //   code: "990J0M74T07-110",
      //   price: "2490",
      // },
      {
        item: "Rear Glass Spoiler (Blue) | Ciaz",
        img: "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/marutigeniuneaccessories_27112019/old_20210309/r5/990j0m79mq0-080_1.jpg",
        code: "990J0M79MQ0-080",
        price: "2490",
      },
      {
        item: "Rear Windshield Spoiler (White) | Ciaz",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/r5/990j0m79mq0-070_1.jpg",
        code: "990J0M79MQ0-070",
        price: "2490",
      },
      {
        item: "Rear Glass Spoiler (Snow White)  | Ciaz",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/r5/990j0m79mq0-050_1.jpg",
        code: "990J0M79MQ0-050",
        price: "2490",
      },
      {
        item: "Premium Graphics - Summit Seeker | Jimny",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/10/990j0m80tj2-010_0.jpg",
        code: "990J0M80TJ2-010",
        price: "2500",
      },
      {
        item: "Anti Skid Mat - Large (Black) | Grand Vitara (Zeta+, Alpha+ Variant)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/r13/990j0m999h2-920_1.jpg",
        code: "990J0M999H2-920",
        price: "149",
      },
      {
        item: "Ecstar Antirust Coating (1250 ml)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/11/990j0m999h1-012_1.jpg",
        code: "990J0M999H1-012",
        price: "1360",
      },
      {
        item: "ORVM Garnish - INVICTO (Alpha+, Zeta+)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/11/990j0m59z13-090_0.jpg",
        code: "990J0M59Z13-090",
        price: "4495",
      },
      {
        item: "Door Visor - (Midnight Black + Garnish Insert) - INVICTO (Alpha+, Zeta+)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/11/990j0m59zq0-010_0.jpg",
        code: "990J0M59ZQ0-010",
        price: "7890",
      },
      {
        item: "Wireless Charger - INVICTO (Alpha+, Zeta+)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/11/990j0m59zm3-010_0.jpg",
        code: "990J0M59ZM3-010",
        price: "10390",
      },
      {
        item: "Rear Upper Spoiler (Grandeur Grey) | XL6 (Zeta/Alpha)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/04/18/990j0m72r07-200_1.jpg",
        code: "990J0M72R07-200",
        price: "4900",
      },
      {
        item: "Rear Upper Spoiler (Brave Khaki) | XL6 (Zeta/Alpha)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/04/18/990j0m72r07-210_1.jpg",
        code: "990J0M72R07-210",
        price: "4900",
      },
      {
        item: "Rear Upper Spoiler (Opulent Red) | XL6 (Zeta/Alpha)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/04/18/990j0m72r07-350_1.jpg",
        code: "990J0M72R07-350",
        price: "4900",
      },
      {
        item: "Rear Upper Spoiler (Splendid silver) | Ertiga / XL6",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/04/14/990j0m72r07-220_1.jpg",
        code: "990J0M72R07-220",
        price: "4900",
      },
      {
        item: "Rear Upper Spoiler (Celestial Blue) | XL6 (Zeta/Alpha)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/05/31/990j0m72r07-190_0-jpg.jpg",
        code: "990J0M72R07-190",
        price: "4900",
      },
      {
        item: "Premium Carpet Mat - Executive Comfort - INVICTO (Alpha+, Zeta+)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/11/990j0m59z10_0.jpg",
        code: "990J0M59Z10",
        price: "2945",
      },
      {
        item: "Alloy Wheel Black 38.10 cm (15) | Ciaz",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/02/43210m79m81-qj8_1.jpg",
        code: "43210M79M50-0CE",
        price: "6390",
      },
      {
        item: "Exterior Styling Kit - Grey + Red | Fronx",
        img: "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/07/990j0m74t07-090_1.jpg",
        code: "990J0M74T07-090",
        price: "7290",
      },
      {
        item: "Alloy Wheel Grey 40.64 cm (16)",
        img: "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q1/43210m79m60-zmq_1.jpg",
        code: "43210M79M81-QJ8",
        price: "9250",
      },
      {
        item: "Tread Metal Alloy Wheel | Jimny (Zeta)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/09/43210m80t00-0bk_1.jpg",
        code: "43210M80T00-0BK",
        price: "8100",
      },
      // {
      //   item: "Alloy Wheel Grey 38.1 cm (15)",
      //   img: "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/marutigeniuneaccessories_27112019/2021/04-revised1/43210m79m50-27n_1.jpg",
      //   code: "43210M79M50-27N",
      //   price: "8390",
      // },
      {
        item: "Future Edge Alloy Wheel | New Baleno (Sigma & Delta Variant)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/02/43210m55ta0-qc8_1.jpg",
        code: "43210M55TA0-QC8",
        price: "8550",
      },
      {
        item: "Alloy Wheel 40.64 cm (16)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/02/43210m68p92-qc8_1.jpg",
        code: "43210M79M60-QC8",
        price: "8690",
      },
      {
        item: "Painted Finish Alloy Wheel (16) | Fronx",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/07/43210m74t50-qc8_0.jpg",
        code: "43210M74T50-QC8",
        price: "8690",
      },
      {
        item: "Techno Lines Machined Alloy Wheel | New Baleno (Sigma & Delta Variant)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/05/31/43210m55tb0-0ce_0-jpg.jpg",
        code: "43210M55TB0-0CE",
        price: "7490",
      },



      {
        item: "Machined Finish Alloy Wheel (16) | Fronx",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/07/43210m74t60-qc8_0.jpg",
        code: "43210M74T60-QC8",
        price: "9190",
      },
      {
        item: "Alloy Wheel Grey 40.64 cm (16) | Ciaz",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/02/43210m79m81-qj8_1.jpg",
        code: "43210M79M81-QJ8",
        price: "9250",
      },
      {
        item: "Trunk Lid Spoiler (Dignity Brown) | Ciaz",
        img: "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/marutigeniuneaccessories_27112019/old_20210309/r4/990j0m79m07-370_1.jpg",
        code: "990J0M79M07-370",
        price: "9990",
      },
      {
        item: "Trunk Lid Spoiler (NEXA Blue) | Ciaz",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/r4/990j0m79m07-350_1.jpg",
        code: "990J0M79M07-340",
        price: "9990",
      },
      {
        item: "Trunk Lid Spoiler (Premium Silver) | Ciaz",
        img: "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/marutigeniuneaccessories_27112019/old_20210309/r4/990j0m79m07-360_1.jpg",
        code: "990J0M79M07-360",
        price: "9990",
      },
      {
        item: "Trunk Lid Spoiler (Snow White) | Ciaz",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/r4/990j0m79m07-350_1.jpg",
        code: "990J0M79M07-350",
        price: "9990",
      },
      {
        item: "Trunk Lid Spoiler (Midnight Black) | Ciaz",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/r4/990j0m79m07-380_1.jpg",
        code: "990J0M79M07-400",
        price: "9990",
      },
      {
        item: "Trunk Lid Spoiler (Glistening Grey) | Ciaz",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/r4/990j0m79m07-380_1.jpg",
        code: "990J0M79M07-380",
        price: "9990",
      },
      {
        item: "Painted Alloy Wheel | Grand Vitara (Sigma, Delta Variant)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/05/43210m76t40-27n_1.jpg",
        code: "43210M76T40-27N",
        price: "10790",
      },
      {
        item: "Machined Finish Alloy Wheel | Grand Vitara (Sigma, Delta Variant)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/05/43210m76t50-qc8_1.jpg",
        code: "43210M76T50-QC8",
        price: "11290",
      },
      {
        item: "Roof Carrier Rails | Grand Vitara (Zeta+, Alpha, Alpha+ Variant)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/17/78901m76t00_0.jpg",
        code: "78901M76T00",
        price: "13990",
      },
      {
        item: "Premium Roof Bars | Jimny",
        img: "https://marutistoragenew1.azureedge.net/paccmicroservice/99176-78R11/99176-78r11_0.jpg",
        code: "99176-78R10",
        price: "15990",
      },
      {
        item: "Premium Roof Basket | Jimny",
        img: "https://marutistoragenew1.azureedge.net/paccmicroservice/99177-78R01/99177-78r00_0.jpg",
        code: "99177-78R00",
        price: "23590",
      },
      {
        item: "Roof Basket | Grand Vitara (Zeta+, Alpha, Alpha+ Variant)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/17/78901m76t00_0.jpg",
        code: "990J0M76TR0-010",
        price: "25990",
      },
      {
        item: "Door Edge Guard (Black)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2021/04-revised1/87418m84020_1.jpg",
        code: "87418M84020",
        price: "5",
      },
      {
        item: "Spare Wheel Cover - Bluish Black | Jimny",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/10/990j0m80tst-010_0.jpg",
        code: "990J0M80TST-010",
        price: "12990",
      },
      {
        item: "Front Bumper Garnish | Jimny",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/09/990j0m80t07-020_1.jpg",
        code: "990J0M80T07-020",
        price: "890",
      },
      {
        item: "Front Skid Plate - Tread Metal | Jimny",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/10/990j0m80t07-040_0.jpg",
        code: "990J0M80T07-040",
        price: "2350",
      },
      {
        item: "Wheel Cover Silver 38.10cm (15)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2021/03/0203/43250m66r00-27n_1.jpg",
        code: "43250M66R00-27N",
        price: "460",
      },
      {
        item: "Alloy Wheel Center Cap (Grey)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/07/990j0m99904-010_1.jpg",
        code: "990J0M99904-010",
        price: "99",
      },
      {
        item: "Car Emblem - Maruti Suzuki logo (Chrome)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/12/77821m75j00_1.jpg",
        code: "77821M75J00",
        price: "104.50",
      },
      {
        item: "Door Edge Guard (Metallic) | 4 Pieces",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q43/990j0m999pa-020_1.jpg",
        code: "990J0M999PA-020",
        price: "250",
      },
      {
        item: "Shark Fin Spoiler (Primered)",
        img: "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/08/24/990j0m67l07-110_1.jpg",
        code: "990J0M67L07-110",
        price: "550",
      },
      // {
      //   item: "Shark Fin Spoiler (Arctic White)",
      //   img: "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/marutigeniuneaccessories_27112019/2020/12/990j0m67l07-080_1.jpg",
      //   code: "990J0M67L07-080",
      //   price: "650",
      // },
      // {
      //   item: "Shark Fin Spoiler (Silky Silver)",
      //   img: "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/marutigeniuneaccessories_27112019/2020/12/990j0m67l07-060_1.jpg",
      //   code: "990J0M67L07-060",
      //   price: "650",
      // },
      {
        item: "Bumper Corner Protector (Carbon Finish)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/05/25/990j0m99917-200_1-jpg.jpg",
        code: "990J0M99917-200",
        price: "1490",
      },
    ],
  },
  {
    filedName: "Interior",
    data: [
      {
        item: "Diamond Cityscape Red Lining Finish Seat Cover (7S) - INVICTO (Zeta+)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/11/990j0m59zs3-010_1.jpg",
        code: "990J0M59ZS3-010",
        price: "10390",
      },
      {
        item: "Interior Styling Kit -Dash and Door - Summit Seeker | JIMNY (Alpha)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/11/990j0m80tpj-050_1.jpg",
        code: "990J0M80TPJ-050",
        price: "5990",
      },
      {
        item: "Black Mat - Executive Lining - INVICTO (Alpha+, Zeta+)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/11/990j0m59za0_1.jpg",
        code: "990J0M59ZA0",
        price: "5320",
      },
      {
        item: "Door Sill Guard | Ciaz",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2021/04-revised1/990j0m79mp6-010_1.jpg",
        code: "990J0M79MP6-010",
        price: "829",
      },

      {
        item: "Deluxe Carpet Mat | New Baleno",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2022/01/75901m55t00_1.jpg",
        code: "75901M55T00",
        price: "1090",
      },
      {
        item: "3D Mat - Dicky | Ertiga/XL6",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q25/990j0m72ra3-020_1.jpg",
        code: "990J0M72RA3-020",
        price: "1090",
      },
      {
        item: "Stainless Steel Door Sill Guard | XL6",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/r1/990j0m72sp6-010_1.jpg",
        code: "990J0M72SP6-010",
        price: "1190",
      },
      {
        item: "Boot Mat - PVC (Black) |   Celerio",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/04/celerio/990j0m81ra3-010_0-jpg.jpg",
        code: "990J0M81RA3-010",
        price: "1390",
      },
      {
        item: "3D Boot Mat (Black) | Baleno",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/04/baleno/990j0m68pa3-020_0-jpg.jpg",
        code: "990J0M68PA3-020",
        price: "1590",
      },
      {
        item: "Deluxe Carpet Mat (Beige) | Ciaz",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q5/75901m79m22_1.jpg",
        code: "75901M79M22",
        price: "1391",
      },
      // {
      //   item: "Boot Mat - Transparent | Ciaz",
      //   img: "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q29/990j0m79ma3-020_1.jpg",
      //   code: "990J0M79MA3-020",
      //   price: "1410",
      // },
      // {
      //   item: "PVC Mat - Full Floor (Black) | Ciaz",
      //   img: "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q5/75901m79md1_1.jpg",
      //   code: "75901M79MD1",
      //   price: "1471",
      // },
      {
        item: "Designer Mat - Tread Metal (MT) | Jimny",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/10/75901m80ta0_0.jpg",
        code: "75901M80TA0",
        price: "1650",
      },
      {
        item: "Designer Mat - Tread Metal (AT) | Jimny",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/10/75901m80tb0_0.jpg",
        code: "75901M80TB0",
        price: "1650",
      },
      // {
      //   item: "Door Sill Guard (Techno Lines) | New Baleno",
      //   img: "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/04/baleno/990j0m55tm8-010_0-jpg.jpg",
      //   code: "990J0M55TM8-010",
      //   price: "1690",
      // },
      {
        item: "Door Sill Guard (Future Edge) | New Baleno",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/04/baleno/990j0m55tm8-020_0-jpg.jpg",
        code: "990J0M55TM8-020",
        price: "1690",
      },
      {
        item: "ORVM Cover (Piano Black) | Ignis / New Baleno (Sigma Variant)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2021/1602/990j0m66rpj-140_1.jpg",
        code: "990J0M66RPJ-140",
        price: "1750",
      },
      {
        item: "ORVM Winker Cover (Piano Black) | New Baleno (Alpha Zeta & Delta Variant)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2021/1602/990j0m66rpj-120_1.jpg",
        code: "990J0M66RPJ-120",
        price: "1790",
      },

      // {
      //   item: "Transparent Mat - Full Floor | Ciaz",
      //   img: "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/marutigeniuneaccessories_27112019/2021/04-revised1/7590179me1_1.jpg",
      //   code: "75901M79ME1",
      //   price: "1876",
      // },
      {
        item: "Protective Door Sill Guard | Fronx ",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/07/990j0m74tm8-010_0.jpg",
        code: "990J0M74TM8-010",
        price: "1890",
      },
      {
        item: "3D Boot Mat | Fronx ",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/07/990j0m74ta3-010_1.jpg",
        code: "990J0M74TA3-010",
        price: "1890",
      },
      {
        item: " Deluxe Carpet Mat | XL6  ",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/04/18/75901m51u10_1.jpg",
        code: "75901M51U10",
        price: "1890",
      },
      {
        item: " Transparent Mat | Grand Vitara",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/17/75901m76tb0_0.jpg",
        code: "75901M76TB0",
        price: "1990",
      },
      {
        item: "Black Mat | Grand Vitara",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/17/75901m76ta0_0.jpg",
        code: "75901M76TA0",
        price: "1990",
      },
      {
        item: "Mystic Scuff Door Sill Guard | Grand Vitara",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/17/990j0m76tp6-010_0.jpg",
        code: "990J0M76TP6-010",
        price: "1990",
      },

      {
        item: "All Weather Boot Mat - Tread Metal| Jimny",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/10/990j0m80ta3-030_0.jpg",
        code: "990J0M80TA3-030",
        price: "2010",
      },
      {
        item: "3D Boot Mat | Grand Vitara (Sigma, Delta, Zeta, Alpha Variant)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/19/990j0m76ta3-010_1.jpg",
        code: "990J0M76TA3-010",
        price: "2150",
      },
      {
        item: "Compact 3D Boot Mat | Grand Vitara (Zeta+, Alpha+ Variant)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/19/990j0m76ta3-020_1.jpg",
        code: "990J0M76TA3-020",
        price: "2150",
      },
      {
        item: "Red Dash Designer Mat | Fronx",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/07/75901m74tc0_0.jpg",
        code: "75901M74TC0",
        price: "2150",
      },
      {
        item: "Premium Carpet Mat (Transparent) | New Baleno",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/02/75901m55tb0_1.jpg",
        code: "75901M55TB0",
        price: "2190",
      },
      {
        item: "Mystic Designer Mat | Grand Vitara",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/17/75901m76tc0_0.jpg",
        code: "75901M76TC0",
        price: "2190",
      },
      {
        item: "Boot Mat - 3D (Black) | Ciaz",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/10/990j0m79ma3-040_1.jpg",
        code: "990J0M79MA3-040",
        price: "2190",
      },
      {
        item: "Designer Mat (Black & Beige) | Ciaz",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q5/75901m82ra0_1.jpg",
        code: "75901M82RA0",
        price: "2250",
      },
      {
        item: "Mat (Black) | New Baleno",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/02/75901m55ta0_1.jpg",
        code: "75901M55TA0",
        price: "2290",
      },
      {
        item: "Protective Door Sill Guard | XL6",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/05/31/990j0m72sm8-010_0-jpg.jpg",
        code: "990J0M72SM8-010",
        price: "2350",
      },
      {
        item: "ORVM Cover (Carbon Fibre) - W/o Winker Slot | Ignis  New Baleno (Sigma Variant)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/09/990j0m66rpj-130_1.jpg",
        code: "990J0M66RPJ-130",
        price: "2350",
      },
      {
        item: "ORVM Cover (Carbon Fibre) - With Winker Slot | Ignis  New Baleno (Alpha Zeta & Delta Variants)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/09/990j0m66rpj-130_1.jpg",
        code: "990J0M66RPJ-110",
        price: "2390",
      },
      {
        item: "Mystic Metal Door Sill Guard | Grand Vitara",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/17/990j0m76tm8-010_0.jpg",
        code: "990J0M76TM8-010",
        price: "2490",
      },
      {
        item: "Premium Carpet Mat | Grand Vitara",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/17/75901m76t00_0.jpg",
        code: "75901M76T00",
        price: "2690",
      },
      {
        item: "All Weather 3D Mat | Grand Vitara",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/17/75901m76td0_0.jpg",
        code: "75901M76TD0",
        price: "2990",
      },
      {
        item: "Premium Carpet Mat - Executive Comfort - INVICTO (Alpha+, Zeta+)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/11/990j0m59z10_0.jpg",
        code: "990J0M59Z10",
        price: "2945",
      },
      {
        item: "All Weather 3D Mat | New Baleno",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/05/31/75901m55tf0_0-jpg.jpg",
        code: "75901M55TF0",
        price: "2990",
      },
      {
        item: "Door Sill Guard (Electric Edge) | New Baleno",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/04/baleno/990j0m55tm6-010_0-jpg.jpg",
        code: "990J0M55TM6-010",
        price: "2990",
      },
      {
        item: " Illuminated Door Sill Guard | Fronx",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/07/990j0m74tm6-010_0.jpg",
        code: "990J0M74TM6-010",
        price: "2990",
      },
      {
        item: " All Weather 3D Mat | Fronx",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/07/75901m74tf0_0.jpg",
        code: "75901M74TF0",
        price: "2990",
      },
      // {
      //   item: " Illuminated Door Sill Guard - Tread Metal | Jimny",
      //   img: " https://marutisuzukiarenaprodcdn.azureedge.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/10/990j0m80tm6-010_0.jpg",
      //   code: "990J0M80TM6-010",
      //   price: "2999",
      // },
      {
        item: " Premium Carpet Mat (White) | New Baleno",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/10/990j0m80tm6-010_0.jpg",
        code: "75901M55T40",
        price: "3190",
      },
      {
        item: "Door Sill Guard - Illuminated | XL6 ",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/r1/990j0m72sm6-010_1.jpg",
        code: "990J0M72SM6-010",
        price: "3190",
      },
      {
        item: "Premium Carpet Mat (AT) | Jimny ",
        img: " https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/10/75901m80tf0_0.jpg",
        code: "75901M80TF0",
        price: "3250",
      },


      {
        item: "Premium Carpet Mat (MT) | Jimny",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/10/75901m80td0_0.jpg ",
        code: "75901M80TD0",
        price: "3250",
      },
      {
        item: "All Weather Mat (MT) - Tread Metal| Jimny ",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/10/75901m80th0_0.jpg",
        code: "75901M80TH0",
        price: "3300",
      },
      {
        item: "All Weather Mat (AT) - Tread Metal | Jimny ",
        img: " https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/10/75901m80tk0_0.jpg",
        code: "75901M80TK0",
        price: "3300",
      },
      {
        item: " Door Sill Guard - Illuminated | Ciaz ",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/12/990j0m79mm6-030_1.jpg",
        code: "990J0M79MM6-030",
        price: "3349",
      },
      {
        item: " Premium Carpet Mat | XL6",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/04/18/75901m51u00_1.jpg",
        code: "75901M51U00",
        price: "3690",
      },
      {
        item: " Premium PVC Black Mat | XL6",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/04/18/75901m51ua0_1.jpg",
        code: "75901M51UA0",
        price: "3790",
      },
      {
        item: " All Weather 3D Mat | XL6 ",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/05/31/75901m51ub0_0-jpg.jpg",
        code: "75901M51UB0",
        price: "3890",
      },
      {
        item: " Full Floor Cover Mat (Basic) | Baleno",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/05/10/990j0m55ta3-020_1-jpg.jpg",
        code: "990J0M55TA3-020",
        price: "3920",
      },
      {
        item: " Full Floor Cover Mat (Basic) | Ignis",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/05/10/990j0m66ra3-030_1-jpg.jpg ",
        code: "990J0M66RA3-030",
        price: "3930",
      },
      {
        item: " Illuminated Door Sill Guard - Luxe Black | Grand Vitara (Zeta+, Alpha+ Variant)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/17/990j0m76tm6-020_0.jpg",
        code: "990J0M76TM6-020",
        price: "3990",
      },
      {
        item: " Illuminated Door Sill Guard - Luxe Brown | Grand Vitara (Sigma, Delta, Zeta, Alpha Variant)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/17/990j0m76tm6-010_0.jpg",
        code: "990J0M76TM6-010",
        price: "3990",
      },
      {
        item: " Interior Styling Kit (Wooden) | Ciaz (V & Z)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/11/990j0m79mpj-080_1.jpg",
        code: "990J0M79MPJ-080",
        price: "4390",
      },
      {
        item: " Black Mat - Executive Lining - INVICTO (Alpha+, Zeta+)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/11/990j0m59za0_1.jpg",
        code: "990J0M59ZA0",
        price: "4795",
      },
      {
        item: " Sun Control Roof Film CR-70 | GRAND VITARA",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/07/990j0m76tsf-010_1.jpg ",
        code: "990J0M76TSF-010",
        price: "4990",
      },
      {
        item: "Full Floor Cover Mat (Premium) | Ignis",
        code: "990J0M66RA3-040",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/07/24/990j0m66ra3-040_1.jpg",
        price: "5,320",
      },
      {
        item: "Sun Control Roof Film CR-40 | GRAND VITARA",
        code: "990J0M76TSF-020",
        img: "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/07/990j0m76tsf-020_1.jpg",
        price: "5,490",
      },
      {
        item: "Full Floor Cover Mat (Premium) | Ciaz",
        code: "990J0M79MA3-050",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/07/24/990j0m79ma3-050_1.jpg",
        price: "5,510",
      },
      {
        item: "Full Floor Cover Mat (Premium) | Baleno",
        code: "990J0M55TA3-030",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/07/24/990j0m55ta3-030_1.jpg",
        price: "5,510",
      },
      {
        item: "All Weather Mat - Executive Lining - INVICTO (Alpha+, Zeta+)",
        code: "990J0M59ZB0",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/11/990j0m59zb0_1.jpghttps://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/11/990j0m59zb0_1.jpg",
        price: "5,745",
      },
      {
        item: "Interior Styling Kit -Dash and Door - Summit Seeker | JIMNY (Alpha)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/11/990j0m80tpj-050_1.jpg",
        price: "5 990",
        category: "Styling Kit",
        code: "990J0M80TPJ-050",
      },
      {
        item: "Sun Control Roof Film CR-20 | GRAND VITARA",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/07/990j0m76tsf-030_1.jpg",
        price: "6 490",
        category: "Sun Films",
        code: "990J0M76TSF-030",
      },
      {
        item: "Interior Styling Kit (Indian Rose Wood) | New Baleno (Alpha Variant)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/05/25/990j0m55tpj-030_1-jpg.jpg",
        price: "6 690",
        category: "Styling Kit",
        code: "990J0M55TPJ-030",
      },
      {
        item: "Interior Styling Kit (Techno Geometric Cross) | New Baleno (Alpha Variant)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/05/25/990j0m55tpj-060_1-jpg.jpg",
        price: "6 990",
        category: "Styling Kit",
        code: "990J0M55TPJ-060",
      },
      {
        item: "Interior Styling Kit - Speaker Trim - Forest Trail | Jimny",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/11/990j0m80tpj-040_1.jpg",
        price: "6 990",
        category: "Styling Kit",
        code: "990J0M80TPJ-040",
      },
      {
        item: "Silver Highway Finish (SAB) Seat Cover (Alpha/Zeta)",
        code: "990J0M55TS3-020",
        price: "6 990",
        category: "Seat Cover SAB",
        description: "990J0M55TS3-020",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/04/990j0m55ts3-021_1-jpg.jpg",
      },
      {
        item: "Interior Styling Kit - Speaker Trim - Summit Seeker | Jimny",
        code: "990J0M80TPJ-020",
        price: "6 990",
        category: "Styling Kit",
        description: "990J0M80TPJ-020",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/11/990j0m80tpj-020_1.jpg",
      },
      {
        item: "Interior Styling Kit - Infotainment Panel, Dash and Door - Forest Trail | Jimny (Zeta)",
        code: "990J0M80TPJ-030",
        price: "6 990",
        category: "Styling Kit",
        description: "990J0M80TPJ-030",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/11/990j0m80tpj-030_1.jpg",
      },
      {
        item: "Interior Styling Kit - Carbon Finish | Fronx",

        code: "990J0M74TPJ-010",
        price: "6 990",
        category: "Styling Kit",
        description: "990J0M74TPJ-010",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/07/990j0m74tpj-010_0.jpg",
      },
      {
        item: "Interior Styling Kit - Infotainment Panel, Dash and Door - Summit Seeker |Jimny (Zeta)",

        code: "990J0M80TPJ-010",
        price: "6 990",
        category: "Styling Kit",
        description: "990J0M80TPJ-010",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/11/990j0m80tpj-030_1.jpg",
      },
      {
        item: "Seat Cover - Silver Highway Finish (SAB) | New Baleno (Alpha/Zeta)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/04/990j0m55ts3-021_1-jpg.jpg",
        code: "990J0M55TS3-021",
        price: "6 990",
        category: "Seat Cover SAB",
      },
      {
        item: "Full Floor Cover Mat (Premium) | XL6",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/07/24/990j0m72sa3-020_1.jpg",
        code: "990J0M72SA3-020",
        price: "7 630",
        category: "Mats",
      },
      {
        item: "X-Factor Black Lining Finish Seat Cover | Fronx",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/07/990j0m74tb3-050_1.jpg",
        code: "990J0M74TB3-050",
        price: "8 170",
        category: "Seat Covers",
      },
      {
        item: "Interior Styling Kit - Armrest| Ertiga/XL6",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2022/01/990j0m72spj-010_0-jpg.jpg",
        code: "990J0M72SPJ-010",
        price: "8 190",
        category: "Styling Kit",
      },
      {
        item: "Tread Booster Bordeaux Finish Seat Cover | Fronx",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/07/990j0m74tb3-060_1.jpg",
        code: "990J0M74TB3-060",
        price: "8 190",
        category: "Seat Covers",
      },
      {
        item: "Interior Styling Kit (Indian Rose Wood) | New Baleno (Delta & Zeta Variant)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/05/25/990j0m55tpj-020_1-jpg.jpg",
        code: "990J0M55TPJ-020",
        price: "8290",
      },
      {
        item: "Seat Cover Valour Lining Finish | Grand Vitara (Sigma, Delta Variant)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/05/990j0m76tb3-040_1.jpg",
        code: "990J0M76TB3-040",
        price: "8290",
      },
      {
        item: "Seat Cover Dual Tone Intense Liner Finish (SAB) | New Baleno (Alpha/Zeta)",
        img: "https://marutistoragenew1.azureedge.net/paccmicroservice/msga/990j0m55ts3-011_1.jpg",
        code: "990J0M55TS3-011",
        price: "8390",
      },
      {
        item: "Dual Tone Intense Liner Finish (SAB) Seat Cover (Alpha/Zeta)",
        img: "https://marutistoragenew1.azureedge.net/paccmicroservice/msga/990j0m55ts3-011_1.jpg",
        code: "990J0M55TS3-010",
        price: "8390",
      },
      {
        item: "Interior Styling Kit (Indian Rose Wood) | New Baleno (Sigma Variant)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/05/25/990j0m55tpj-010_1-jpg.jpg",
        code: "990J0M55TPJ-010",
        price: "8390",
      },
      {
        item: "Interior Styling Kit - Copper Mystic Marble | Grand Vitara (Sigma, Delta, Zeta, Alpha Variant)",
        code: "990J0M76TPJ-020",
        price: "8,490",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/17/990j0m76tpj-020_0.jpg",
      },
      {
        item: "Interior Styling Kit - Grey Mystic Marble | Grand Vitara (Sigma, Delta, Zeta, Alpha Variant)",
        code: "990J0M76TPJ-010",
        price: "8,490",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/17/990j0m76tpj-010_0.jpg",
      },
      {
        item: "Interior Styling Kit - Luxe Dawn Wood | Grand Vitara (Sigma, Delta, Zeta, Alpha Variant)",
        code: "990J0M76TPJ-030",
        price: "8,490",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/17/990j0m76tpj-030_0.jpg",
      },
      {
        item: "Interior Styling Kit - Luxe Dusk Wood | Grand Vitara (Sigma, Delta, Zeta, Alpha Variant)",
        code: "990J0M76TPJ-040",
        price: "8,490",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/17/990j0m76tpj-040_0.jpg",
      },
      {
        item: "Seat Cover - Premium NEXA Lining (Premium PU) | Ciaz (Zeta)",
        code: "990J0M79MB3-340",
        price: "8,569",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q29/990j0m79mb3-340_1.jpg",
      },
      {
        item: "Seat Cover - Cityscape Premium (Premium PU) | Ciaz (Zeta)",
        code: "990J0M79MB3-280",
        price: "8,569",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q29/990j0m79mb3-280_1.jpg",
      },
      {
        item: "Seat Cover - Premium Diamond Finish (Premium PU) | Ciaz (Sigma & Delta)",
        code: "990J0M79MB3-330",
        price: "8,569",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q29/990j0m79mb3-330_1.jpg",
      },
      {
        item: "Interior Styling Kit (Techno Geometric Cross) | New Baleno (Delta & Zeta Variant)",
        code: "990J0M55TPJ-050",
        price: "8,790",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/05/25/990j0m55tpj-050_1-jpg.jpg",
      },
      {
        item: "X-Factor Silver Lining Finish Sleeve Seat Cover | Fronx",
        code: "990J0M74TS3-120",
        price: "8,790",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/07/990j0m74ts3-120_1.jpg",
      },
      {
        item: "X-Factor Bordeaux Lining Finish Sleeve Seat Cover | Fronx",
        code: "990J0M74TS3-110",
        price: "8,790",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/07/990j0m74ts3-110_1.jpg",
      },

      {
        item: "Tread Booster Black Finish Sleeve Seat Cover | Fronx",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/07/990j0m74ts3-130_1.jpg",
        code: "990J0M74TS3-130",
        price: "8,810",
      },
      {
        item: "Tread Booster Red Finish Sleeve Seat Cover | Fronx",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/07/990j0m74ts3-140_1.jpg",
        code: "990J0M74TS3-140",
        price: "8,810",
      },
      {
        item: "Seat Cover - Techno Lines Blue Highlight(PU) | New Baleno (Delta & Sigma Petrol/CNG Variant)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/04/990j0m55tb3-011_1-jpg.jpg",
        code: "990J0M55TB3-011",
        price: "8,820",
      },
      {
        item: "Power Scales Silver Finish Seat Cover | Fronx",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/07/990j0m74tb3-030_1.jpg",
        code: "990J0M74TB3-030",
        price: "8,840",
      },
      {
        item: "Seat Cover - Dual Tone Future Finish(PU) | New Baleno (Delta & Sigma Petrol/CNG Variant)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/04/990j0m55tb3-041_1-jpg.jpg",
        code: "990J0M55TB3-041",
        price: "8,880",
      },
      {
        item: "Interior Styling Kit (Techno Geometric Cross) | New Baleno (Sigma Variant)",
        code: "990J0M55TPJ-040",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/05/25/990j0m55tpj-040_1-jpg.jpg",
        price: " 8,890",
        category: "Interior Styling Kit",
      },
      {
        item: "Seat Cover - Premium NEXA Lining (Premium PU) | Ciaz (Sigma & Delta)",
        code: "990J0M79MB3-310",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q29/990j0m79mb3-310_1.jpg",
        price: " 8,899",
        category: "Seat Covers",
      },
      {
        item: "Seat Cover - Premium Curve Lining Highlight (Premium PU) | Ciaz (Zeta)",
        code: "990J0M79MB3-320",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q29/990j0m79mb3-320_1.jpg",
        price: " 8,899",
        category: "Seat Covers",
      },
      {
        item: "Seat Cover - Premium Crown Finish (Premium PU) | Ciaz (Zeta)",
        code: "990J0M79MB3-300",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q29/990j0m79mb3-300_1.jpg",
        price: " 8,899",
        category: "Seat Covers",
      },
      {
        item: "Seat Cover - Cityscape Premium (Premium PU) | Ciaz (Sigma & Delta)",
        code: "990J0M79MB3-250",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q29/990j0m79mb3-250_1.jpg",
        price: " 8,899",
        category: "Seat Covers",
      },

      {
        item: "Seat Cover - (Premium PU) | Ciaz (Zeta)",
        code: "990J0M79MB3-260",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q29/990j0m79mb3-320_1.jpg",
        price: " 8 899",
        category: "Seat Covers",
      },
      {
        item: "Seat Cover - Future Grooves Highlight(PU) | New Baleno (Delta & Sigma Petrol/CNG Variant)",
        code: "990J0M55TB3-021",
        img: "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/04/990j0m55tb3-021_1-jpg.jpg",
        price: " 8 900",
        category: "Seat Covers",
      },
      {
        item: "Seat Cover - Dual Tone Intense Liner Finish(PU) | New Baleno (Delta & Sigma Petrol/CNG Variant)",
        code: "990J0M55TB3-061",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/04/990j0m55tb3-061_1-jpg.jpg",
        price: " 8 970",
        category: "Seat Covers",
      },
      {
        item: "Crystal Cross Bordeaux Finish Seat Cover | Fronx",
        code: "990J0M74TB3-020",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/07/990j0m74tb3-020_1.jpg",
        price: " 9 030",
        category: "Seat Covers",
      },
      {
        item: "Seat Cover Exuberant Brown Highlight Finish | Grand Vitara (Sigma, Delta Variant)",
        code: "990J0M76TB3-060",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/05/990j0m76tb3-060_1.jpg",
        price: " 9 040",
        category: "Seat Covers",
      },

      {
        item: "NexCross Black Finish Seat Cover | Fronx",
        code: "990J0M74TB3-040",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/07/990j0m74tb3-040_1.jpg",
        price: " 9 090",
        category: "Seat Covers",
      },
      {
        item: "Seat Cover Luxe Brown Highlight Fabric Finish | Grand Vitara (Sigma, Delta Variant)",
        code: "990J0M76TB6-010",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/05/990j0m76tb6-010_1.jpg",
        price: " 9 130",
        category: "Seat Covers",
      },
      {
        item: "Cross-Country Black Dash Finish Seat Cover | Fronx",
        code: "990J0M74TB3-010",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/07/990j0m74tb3-010_1.jpg",
        price: " 9 140",
        category: "Seat Covers",
      },
      {
        item: "Seat Cover Luxe Brown Etched Finish | Grand Vitara (Sigma, Delta Variant)",
        code: "990J0M76TB3-050",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/05/990j0m76tb3-050_1.jpg",
        price: " 9 230",
        category: "Seat Covers",
      },
      {
        item: "Adventure Trek Fabric Finish Seat Cover | Jimny",
        code: "990J0M80TS6-010",
        img: "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/09/990j0m80ts6-010_1.jpg",
        price: " 9 310",
        category: "Seat Cover SAB",
      },
      {
        item: "Seat Cover Mystic Drops Dual Tone Finish | Grand Vitara (Sigma, Delta Variant)",
        code: "990J0M76TB3-020",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/05/990j0m76tb3-020_1.jpg",
        price: " 9 390",
        category: "Seat Covers",
      },
      {
        item: "Seat Cover Enigmatic Lines Dual Tone Finish | Grand Vitara (Sigma, Delta Variant)",
        code: "990J0M76TB3-010",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/05/990j0m76tb3-010_1.jpg",
        price: " 9 390",
        category: "Seat Covers",
      },
      {
        item: "Seat Cover Kinetic Lines Dual Tone Finish | Grand Vitara (Sigma, Delta Variant)",
        code: "990J0M76TB3-030",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/05/990j0m76tb3-030_1.jpg",
        price: " 9 390",
        category: "Seat Covers",
      },
      {
        item: "Midnight Trail Finish Seat Cover | Jimny",
        code: "990J0M80TS3-030",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/09/990j0m80ts3-030_1.jpg",
        price: " 9 400",
        category: "Seat Cover SAB",
      },
      {
        item: "Off-Roader Dual Stroke Finish Seat Cover | Jimny",
        code: "990J0M80TS3-020",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/09/990j0m80ts3-020_1.jpg",
        price: " 9 400",
        category: "Seat Cover SAB",
      },
    ],
  },
  {
    filedName: "Car Care",
    data: [
      {
        item: "Super Lubricant",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q7/99000m99178_1.jpg",
        price: "370",
        code: "99000M99178",
      },
      {
        item: "Cabin Air Filter PM 2.5 + Perfume | (Swift, Dzire, Baleno, Fronx, Ertiga, XL6)",
        img: "https://marutistoragenew1.azureedge.net/paccmicroservice/staging/990J0M56RTE-250/990j0m56rte-250_1.jpg",
        price: "725",
        code: "990J0M56RTE-250",
      },
      {
        item: "Cabin Air Filter PM 2.5 + Perfume | (Brezza, Ciaz, S-cross, Grand Vitara)",
        img: "https://marutistoragenew1.azureedge.net/paccmicroservice/staging/990J0M82PTE-220/990j0m82pte-220_1.jpg",
        price: "675",
        code: "990J0M82PTE-220",
      },
      {
        item: "Cabin Air Filter PM 2.5 + Perfume | (Ignis, WagonR)",
        img: "https://marutistoragenew1.azureedge.net/paccmicroservice/990J0M69RTE-210/990j0m69rte-210_1.jpg",
        price: "590",
        code: "990J0M69RTE-210",
      },
      {
        item: "Cabin Air Filter - Combi (Swift, Baleno, Fronx, Ertiga, XL6)",
        img: "https://marutistoragenew1.azureedge.net/paccmicroservice/990J0M56RS0-030/990j0m56rs0-030_2.jpg",
        price: "900",
        code: "990J0M56RS0-030",
      },
      {
        item: "Cabin Air Filter - Antimicrobial (Swift, Baleno, Fronx, Ertiga, XL6)",
        img: "https://marutistoragenew1.azureedge.net/paccmicroservice/990J0M56RS0-040/990j0m56rs0-040_2.jpg",
        price: "550",
        code: "990J0M56RS0-040",
      },
      {
        item: "Cabin Air Filter - Activated Carbon (Swift, Baleno, Fronx, Ertiga, XL6)",
        img: "https://marutistoragenew1.azureedge.net/paccmicroservice/990J0M56RS0-020/990j0m56rs0-020_2.jpg",
        price: "775",
        code: "990J0M56RS0-020",
      },
      {
        item: "PM 2.5 AC Filter | (WagonR & Ignis)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/12/990j0m69rs0-010_1.jpg",
        price: "990J0M69RS0-010",
        code: "550",
      },
      {
        item: "Cabin Air Filter - PM2.5",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2021/04-revised/990j0m56rs0-010_1.jpg",
        price: "599",
        code: "990J0M56RS0-010",
      },
      {
        item: "PM10 Cabin Air Filter & Organic Perfume (Lemon) Package | Ciaz",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/r5/990j0m79mte-140_1.jpg",
        price: "600",
        code: "990J0M79MTE-140",
      },
      {
        item: "Activated Carbon Filter | (Brezza, Ciaz, S-cross, Grand Vitara)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/12/990j0m82ps0-020_1.jpg",
        price: "790",
        code: "990J0M82PS0-020",
      },
      {
        item: "Body Cover | Fronx",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/07/990j0m74t02-030_1.jpg",
        price: "1 650",
        code: "990J0M74T02-030",
      },
      {
        item: "Car Body Cover (Basic) | New Baleno",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/02/990j0m55t02-020_1.jpg",
        alt: "Car Body Cover (Basic) | New Baleno",
        code: "990J0M55T02-020",
        price: "1 650",
        category: "Body Cover",
        id: "79A8AFB9-262F-4E93-B2C9-5652DF059566",
      },
      {
        item: "Basic Body Cover | Jimny",
        img: "https://marutistoragenew1.azureedge.net/paccmicroservice/990J0M80T02-010/990j0m80t02-010_2.jpg",
        alt: "Basic Body Cover | Jimny",
        code: "990J0M80T02-010",
        price: "1 700",
        category: "Body Cover",
        id: "FC86C8D1-D52A-4038-9750-EEFD27D6F1D1",
      },
      {
        item: "Body Cover | Grand Vitara",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/19/990j0m76t02-030_1.jpg",
        alt: "Body Cover | Grand Vitara",
        code: "990J0M76T02-030",
        price: "1 790",
        category: "Body Cover",
        id: "632E2442-7727-4F47-9FBD-7A0B9A616C5B",
      },
      // {
      //   item: "Body Cover | Grand Vitara",
      //   img: "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/19/990j0m76t02-010_1.jpg",
      //   alt: "Body Cover | Grand Vitara",
      //   code: "990J0M76T02-010",
      //   price: "1 790",
      //   category: "Body Cover",
      //   id: "E2D8DAC8-D9D0-4EB4-AB42-B01C8A9FAD2E",
      // },
      {
        item: "Car Body Cover (Matte) | Ciaz",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2021/03/1003/990j0m79m02-040_1.jpg",
        alt: "Car Body Cover (Matte) | Ciaz",
        code: "990J0M79M02-040",
        price: "1 840",
        category: "Body Cover",
        id: "0F183299-D048-4EE1-A2A9-F6FAA9AFF4F6",
      },
      {
        item: "Car Body Cover (Matte) | XL6",
        id: "20DF60FC-615A-4575-83C3-E718E8EF40BB",
        category: "Body Cover",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2022/01/990j0m72s02-020_1.jpg",
        code: "990J0M72S02-020",
        price: "1 990",
        unitPrice: "1 990 / Number",
      },
      {
        item: "Basic Body Cover - INVICTO (Alpha+, Zeta+)",
        id: "3A2CB8F2-4BBE-4DE7-8CAC-5F91C3808A2A",
        category: "Body Cover",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/11/990j0m59z02-010_0.jpg",
        code: "990J0M59Z02-010",
        price: "3 045",
        unitPrice: "3 045 / Number",
      },
      {
        item: "Car Body Cover (Premium) | New Baleno",
        id: "F48CEC59-9039-4B24-8975-5D3AF5BD1CDF",
        category: "Body Cover",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/02/990j0m55t02-030_1.jpg",
        code: "990J0M55T02-030",
        price: "3 090",
        unitPrice: "3 090 / Number",
      },
      {
        item: "Premium Body Cover | Fronx",
        id: "D7BBF684-4558-4BDB-8007-A110844A03F4",
        category: "Body Cover",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/07/990j0m74t02-020_1.jpg",
        code: "990J0M74T02-020",
        price: "3 090",
        unitPrice: "3 090 / Number",
      },
      {
        item: "Premium Body Cover | Jimny",
        id: "1366F3AE-08A8-4A01-93D5-14ED255B80FF",
        category: "Body Cover",
        img: "https://marutistoragenew1.azureedge.net/paccmicroservice/990J0M80T02-020/990j0m80t02-020_2.jpg",
        code: "990J0M80T02-020",
        price: "3 300",
        unitPrice: "3 300 / Unit",
      },

      {
        item: "Premium Body Cover | Grand Vitara",
        id: "176AA2F3-648B-4355-8733-D7ECF3881CEA",
        category: "Body Cover",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/19/990j0m76t02-020_1.jpg",
        code: "990J0M76T02-020",
        price: "3 490",
        unitPrice: "3 490 / Number",
      },
      {
        item: "Premium Body Cover - INVICTO (Alpha+, Zeta+)",
        id: "AE706EA6-1B3B-448F-827E-8522E1CDCE56",
        category: "Body Cover",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/11/990j0m59z02-010_0.jpg",
        code: "990J0M59Z02-020",
        price: "5 595",
        unitPrice: "5 595 / Number",
      },
      {
        item: "Ecstar Screen Wash (50 ml)",
        id: "48F127F6-5EA4-47AD-BAA2-5A3994AA78BB",
        category: "Windsheild Wash Concentrate",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q36/990j0m999h2-260_1.jpg",
        code: "990J0M999H2-260",
        price: "17",
        unitPrice: "17 / Unit",
      },
      {
        item: "Ecstar Screen Wash (50 ml)",
        id: "EE363932-A0F7-429E-9717-A8723F3C9CBF",
        category: "Windsheild Wash Concentrate",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q36/990j0m999h2-260_1.jpg",
        code: "990J0M999H2-250",
        price: "17",
        unitPrice: "17 / Unit",
      },
      {
        item: "Car Primer (10ml)",
        id: "696508F8-0013-4816-9B47-FEAA30CC5256",
        category: "Workshop Consumables",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/11/990j0m999h7-010_1.jpg",
        code: "990J0M999H7-010",
        price: "45",
        unitPrice: "45 / Unit",
      },

      {
        item: "Tubeless Tyre Repair - Permacure",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2021/04-revised/99000m24120-527_1.jpg",
        code: "99000M24120-527",
        price: "53",
      },
      {
        item: "Microfiber Cloth (Blue)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/10/990j0m999h2-880_1.jpg",
        code: "990J0M999H2-880",
        price: "89",
      },
      {
        item: "Ecstar Automotive Body Shampoo (500 ml)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q7/99000m99097-001_1.jpg",
        code: "99000M99097-001",
        price: "102",
      },
      {
        item: "Ecstar Glass Cleaner (500 ml)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q7/99000m99096-001_1.jpg",
        code: "99000M99096-001",
        price: "110",
      },
      {
        item: "SUPER ADHESIVE (19ML)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q8/99000m99252_1.jpg",
        code: "99000M99252",
        price: "111",
      },
      {
        item: "Sponge Extreme Dirt Buster",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q37/990j0m999h2-490_1.jpg",
        code: "990J0M999H2-490",
        price: "140",
      },
      {
        item: "Double Sided Tape",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/11/990j0m999h7-060_1.jpg",
        code: "990J0M999H7-060",
        price: "160",
      },
      {
        item: "Ecstar Upholstery Cleaner (500 ml)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q7/99000m99139_1.jpg",
        code: "99000M99139",
        price: "160",
      },
      {
        item: "Mircrofibre Cloth (Red)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q36/990j0m999h2-330_1.jpg",
        code: "990J0M999H2-330",
        price: "190",
      },
      // {
      //   item: "Microfibre Cloth (Blue)",
      //   img: "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q36/990j0m999h2-340_1.jpg",
      //   code: "990J0M999H2-340",
      //   price: "209",
      // },
      {
        item: "Sponge Xtreme",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q36/990j0m999h2-290_1.jpg",
        code: "990J0M999H2-290",
        price: "220",
      },
      {
        item: "Ecstar Automotive Body Polish (500 ml)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q7/99000m99094-001_1.jpg",
        code: "99000M99094-001",
        price: "225",
      },
      {
        item: "Ecstar Dash Board Shiner (225 ml)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/12/99000m99095-001_1.jpg",
        code: "99000M99095-001",
        price: "250",
      },
      {
        item: "Cabin Air Filter - PM10",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q6/95861m74l00_1.jpg",
        code: "95861M74L00",
        price: "315",
      },

      {
        item: "Ecstar Tyre Polish (500 ml)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2021/04-revised1/990j0m999cv-tp3_1.jpg",
        code: "99000M99098-001",
        price: " 320",
        category: "Tyre & Wheels Care",
      },
      {
        item: "Car Duster (Small)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/12/990j0m999h2-890_1.jpg",
        code: "990J0M999H2-890",
        price: " 329",
        category: "ALL CAR CARE PRODUCTS & CONSUMABLES ETC",
      },
      {
        item: "Ecstar Wax Kit (100 ml)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/12/990j0m999h2-970_1.jpg",
        code: "990J0M999H2-970",
        price: " 349",
        category: "Polish & Wax",
      },
      {
        item: "Super Lubricant",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q7/99000m99178_1.jpg",
        code: "99000M99178",
        price: " 370",
        category: "Workshop Consumables",
      },
      {
        item: "Antirust Underbody Coating",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2021/02/990j0m999h1-013_1.jpg",
        code: "990J0M999H1-013",
        price: " 410",
        category: "Underbody Anti Rust Coating",
      },
      {
        item: "Application Sponge",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q37/990j0m999h2-500_1.jpg",
        code: "990J0M999H2-500",
        price: " 419",
        category: "Cloth, Duster & Sponge",
      },
      {
        item: "Sponge Multi Application",
        url: "/genuine-accessories/sponge-multi-application/990J0M999H2-280",
        image_url:
          "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q36/990j0m999h2-280_1.jpg",
        code: "990J0M999H2-280",
        price: " 429",
        category: "Cloth, Duster & Sponge",
      },
      {
        item: "Ecstar Car Care Kit Basic",
        url: "/genuine-accessories/ecstar-car-care-kit-basic/990J0M999H2-010",
        image_url:
          "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/r11/990j0m999h2-010_1.jpg",
        code: "990J0M999H2-010",
        price: " 459",
        category: "Car Care Kit",
      },
      // {
      //   item: "AC Gas Floron (340 g)",
      //   url: "/genuine-accessories/ac-gas-floron-340-g/990J0M999H4-030",
      //   image_url:
      //     "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/marutigeniuneaccessories_27112019/old_20210309/r13/990j0m999h4-030_1.jpg",
      //   code: "990J0M999H4-030",
      //   price: " 465",
      //   category: "AC Gas",
      // },
      {
        item: "Interior Cleaner (500 ml)",
        url: "/genuine-accessories/interior-cleaner-500-ml/990J0M999H2-390",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/r12/990j0m999h2-390_1.jpg",
        code: "990J0M999H2-390",
        price: " 469",
        category: "Interior Cleaner",
      },
      {
        item: "Ecstar Windshield Washer (250 ml)",
        url: "/genuine-accessories/ecstar-windshield-washer-250-ml/990J0M999H2-360",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q36/990j0m999h2-360_1.jpg",
        code: "990J0M999H2-360",
        price: " 479",
        category: "Glass Cleaner",
      },
      {
        item: "Ecstar Car Wash (500 ml)",
        url: "/genuine-accessories/ecstar-car-wash-500-ml/990J0M999H2-300",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q36/990j0m999h2-300_1.jpg",
        code: "990J0M999H2-300",
        price: " 479",
        category: "Car Shampoo",
      },
      {
        item: "Super Cleaner (480 ml)",
        url: "/genuine-accessories/super-cleaner-480-ml/99000M99280",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q8/99000m99280_1.jpg",
        code: "99000M99280",
        price: " 480",
        category: "Workshop Consumables",
      },
      // {
      //   item: "Ecstar Chrome & Aluminium Paste (75 ml)",
      //   code: "990J0M999H2-460",
      //   price: " 490",
      //   img: "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/marutigeniuneaccessories_27112019/old_20210309/r12/990j0m999h2-460_1.jpg",
      // },
      {
        item: "Ecstar Car Polish (250 ml)",
        code: "990J0M999H2-310",
        price: " 519",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q36/990j0m999h2-310_1.jpg",
      },
      {
        item: "Car Duster",
        code: "990J0M999H2-910",
        price: " 520",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/12/990j0m999h2-910_1.jpg",
      },
      {
        item: "Ecstar Clear Glass Spray (500 ml)",
        code: "990J0M999H2-480",
        price: " 529",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q37/990j0m999h2-480_1.jpg",
      },
      {
        item: "Tubeless Tyre Repair Kit",
        code: "99000M24120-526",
        price: " 575",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/11/99000m24120-526_1.jpg",
      },
      {
        item: "Germ Klen - For Upholstery",
        code: "990J0M999CV-GM1",
        price: " 575",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2021/04-revised1/990j0m999cv-gm1_1.jpg",
      },
      {
        item: "Germ Klen - For Plastic",
        code: "990J0M999CV-GM2",
        price: " 575",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2021/04-revised1/990j0m999cv-gm2_1.jpg",
      },
      {
        item: "Ecstar Gloss Shampoo (500 ml)",
        code: "990J0M999H2-350",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q36/990j0m999h2-350_1.jpg",
        price: " 590",
      },
      // {
      //   item: "AC Gas Floron (450 g)",
      //   code: "990J0M999H4-050",
      //   img: "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/marutigeniuneaccessories_27112019/2020/11/990j0m999h4-050_1.jpg",
      //   price: " 610",
      // },
      {
        item: "Ecstar Synthetic Chamois",
        code: "990J0M999H2-470",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q37/990j0m999h2-470_1.jpg",
        price: " 629",
      },
      {
        item: "Ecstar Super Liquid Wax (250 ml)",
        code: "990J0M999H2-320",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/02/990j0m999h2-320_1-jpg.jpg",
        price: " 629",
      },
      {
        item: "Leather Care (250 ml)",
        code: "990J0M999H2-430",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/r12/990j0m999h2-430_1.jpg",
        price: " 629",
      },
      {
        item: "Ecstar Glass Cleaner (10000 ml)",
        code: "99000M99096-002",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q7/99000m99096-002_2.jpg",
        price: " 668",
      },
    ],
  },
  {
    filedName: "Infotainment",
    data: [
      {
        item: "Multimedia Stereo - DMH-A245BT (15.7 Cm) | Pioneer",
        code: "990J0M83KD9-690",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/10/06/990j0m83kd9-690_1-png.png",
        price: "14990",
      },
      {
        item: "Multimedia Stereo - DMH-A345BT (17.2 Cm) | Pioneer",
        code: "990J0M83KD9-700",
        img: "https://marutistoragenew1.azureedge.net/paccmicroservice/msga/990j0m83kd9-700_1.png",
        price: "17990",
      },
      {
        item: "Speaker - TS-160M 320W | Pioneer",
        code: "990J0M66LE1-160",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/10/06/990j0m66le1-160_1-png.png",
        price: "4150",
      },
      {
        item: "Multimedia Stereo - XAV AX3200 (17.6 cm) | Sony",
        code: "990J0M83KD9-670",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/03/990j0m83kd9-670_1.jpg",
        price: "24990",
      },
      {
        item: "Speaker Bracket",
        code: "39354M55KB0",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/oldimages/jan/39354m55kb0_1.jpg",
        price: " 107",
      },
      {
        item: "Antenna Pig Tail Assembly",
        code: "990J0M74LM5-010",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/12/990j0m74lm5-010_1.jpg",
        price: " 128",
      },
      {
        item: "Speaker Spacer",
        code: "990J0M83KM5-010",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/07/990j0m83km5-010_1.jpg",
        price: " 183",
      },
      {
        item: "Spacer Ring",
        code: "990J0M999M5-050",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2021/04-revised/990j0m999m5-050_1.jpg",
        price: " 195",
      },
      {
        item: "Speaker Spacer Set",
        code: "990J0M999M5-030",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/10/990j0m999m5-030_1.jpg",
        price: " 537",
      },
      {
        item: "Speaker - 10.16 Cm (4) | Nippon",
        code: "990J0M66LE1-010",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q18/990j0m66le1-010_1.jpg",
        price: " 690",
      },
      {
        item: "Speaker 15.24 cm (6)",
        code: "990J0M60ME3-010",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/02/990j0m60me3-010_1-jpg.jpg",
        price: " 1 210",
      },
      {
        item: "Speaker - Dual Cone 10.16 cm (4) ; 190 W | Pioneer",
        code: "990J0M66LE1-070",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q18/990j0m66le1-070_1.jpg",
        price: " 1,650",
      },
      {
        item: "Speaker 15.24 cm (6) 2-Way",
        code: "990J0M53ME3-030",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2021/04-revised/990j0m53me3-030_1.jpg",
        price: " 1,970",
      },
      {
        item: "Speaker - Dual Cone 15.24 cm (6) ; 280 W | Pioneer",
        code: "990J0M66LE1-090",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q18/990j0m66le1-090_1.jpg",
        price: " 2,090",
      },
      {
        item: "Speaker - 10.16 cm (4) ; 210 W 2-Way | Pioneer",
        code: "990J0M66LE1-080",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q18/990j0m66le1-080_1.jpg",
        price: " 2,190",
      },
      {
        item: "Bluetooth Kit - With Speaker Output (Black)",
        code: "990J0M99919-150",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/r7/990j0m99919-150_1.jpg",
        price: " 2,490",
      },
      {
        item: "Speaker - 15.24 cm (6) ; 300 W 2-Way | Pioneer",
        code: "990J0M66LE1-100",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q19/990j0m66le1-100_1.jpg",
        price: " 2 590",
      },
      {
        item: "Tweeter 80 W | Hertz",
        code: "990J0M999EE-030",
        img: "https://www.marutisuzuki.com/assets/images/product-img-01.jpg",
        price: " 2 690",
      },
      {
        item: "Speaker - 260 W 2-Way | Sony",
        code: "990J0M66LE3-030",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q19/990j0m66le3-030_1.jpg",
        price: " 2 690",
      },
      {
        item: "Speakers - 16 cm ; 260 W 2-Way",
        code: "990J0M66LE3-070",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/11/990j0m66le3-070_1.jpg",
        price: " 2 690",
      },
      {
        item: "Speakers - 15.24 cm (6) ; 250 W 2-Way | JVC",
        code: "990J0M74LE3-020",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q26/990j0m74le3-020_1.jpg",
        price: " 2 790",
      },
      {
        item: "Speakers - 15.24 cm (6) 2-Way",
        code: "990J0M67LE3-020",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q20/990j0m67le3-020_1.jpg",
        price: " 2 490",
      },
      {
        item: "Speaker -280W Coaxial Car Speaker | JBL",
        code: "990J0M999E2-010",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2022/01/990j0m999e2-010_1.png",
        price: " 2 936",
      },
      {
        item: "Speakers - Co-Axial 15.24 cm (6) ; 270 W 3-Way | Sony",
        code: "990J0M67LEA-030",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q20/990j0m67lea-030_1.jpg",
        price: " 3 190",
      },
      {
        item: "Speaker - 15.24 cm (6) ; 300 W 3-Way | Pioneer",
        code: "990J0M66LE1-110",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q19/990j0m66le1-110_1.jpg",
        price: " 3,290",
      },
      {
        item: "Speaker -310W Coaxial Car Speaker | JBL",
        code: "990J0M999E2-020",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2022/01/990j0m999e2-020_1.png",
        price: " 3,355",
      },
      // {
      //   item: "Speaker - Coaxial 200W 2-Way | Hertz",
      //   code: "990J0M999E0-160",
      //   img: "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/marutigeniuneaccessories_27112019/2022/01/990j0m999e0-160_1.png",
      //   price: " 3,489",
      // },
      {
        item: "Speaker 6 x 9 3-Way | Pioneer",
        code: "990J0M53ME4-020",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q11/990j0m53me4-020_1.jpg",
        price: " 4,150",
      },
      {
        item: "Speaker - TS-160M 320W | Pioneer",
        code: "990J0M66LE1-160",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/10/06/990j0m66le1-160_1-png.png",
        price: " 4,150",
      },
      {
        item: "Component Speaker",
        code: "990J0M83KD9-600",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2021/04-revised1/990j0m83kd9-600_1.jpg",
        price: " 4,990",
      },
      {
        item: "Speaker - Coaxial 280W 2-Way | Hertz",
        code: "990J0M999E0-150",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2022/01/990j0m999e0-150_1.png",
        price: "4,422",
      },
      // {
      //   item: "Speakers - Co-Axial 100 W 2-Way | Hertz",
      //   code: "990J0M999EE-040",
      //   img: "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q33/990j0m999ee-040_1.jpg",
      //   price: " 4,490",
      // },
      {
        item: "Speakers - Co-Axial 6 X 9 ; 450 W 3-Way | Sony",
        code: "990J0M79GE4-120",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/r4/990j0m79ge4-120_1.jpg",
        price: " 4,690",
      },
      {
        item: "Stereo - USB/AUX/FM 1 DIN | Sony",
        code: "990J0M83KDK-130",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q31/990j0m83kdk-140_1.jpg",
        price: " 5,990",
      },
      {
        item: "Stereo - 1 DIN | JVC",
        code: "990J0M83KDK-150",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/07/990j0m83kdk-150_1.jpg",
        price: " 5,990",
      },
      {
        item: "Speakers - 6 x 9 ; 180 W 3-Way | Hertz",
        code: "990J0M999EE-050",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/11/990j0m999ee-050_1.jpg",
        price: " 6,450",
      },
      {
        item: "Stereo | MVH-S219BT | Pioneer",
        code: "990J0M999DK-040",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/noimage/msga_noimage.jpg",
        price: " 6,750",
      },
      {
        item: "Stereo - USB/BT/AUX/FM 1 DIN | Sony",
        code: "990J0M83KDK-140",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q31/990j0m83kdk-140_1.jpg",
        price: " 6,990",
      },

      // {
      //   item: "Stereo - USB/AUX/FM 2 DIN | JVC",
      //   code: "990J0M74LDD-010",
      //   img: "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q26/990j0m74ldd-010_1.jpg",
      //   price: " 7660",
      // },
      {
        item: "Stereo - 1 DIN | JVC",
        code: "990J0M74LD3-100",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2021/03/0203/990j0m74ld3-100_1.jpg",
        price: " 7790",
      },
      {
        item: "Stereo - 1 DIN | Kenwood",
        code: "990J0M83KDK-170",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2021/03/0203/990j0m83kdk-170_1.jpg",
        price: " 7790",
      },
      {
        item: "Stereo - 1 Din Mechaless",
        code: "990J0M83KDK-400",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2021/04-revised/990j0m83kdk-400_1.jpg",
        price: " 7990",
      },
      {
        item: "Stereo - USB/AUX 1 DIN with Speakers",
        code: "990J0M67LD3-110",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/07/990j0m67ld3-110_1.png",
        price: " 8790",
      },
      {
        item: "Stereo - BT/USB/AUX/FM 2 DIN | SONY",
        code: "990J0M83KDD-300",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/08/24/990j0m83kdd-300_1.jpg",
        price: " 8990",
      },
      // {
      //   item: "Stereo - CD/BT/USB 2 Din | Pioneer",
      //   img: "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q31/990j0m83kdd-130_1.jpg",
      //   code: "990J0M83KDD-130",
      //   price: "9990",
      // },
      {
        item: "Bass Tube - DST 30.3 30.48 cm (12) ; 250 W | Hertz",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q33/990j0m999e0-040_1.jpg",
        code: "990J0M999E0-040",
        price: "10190",
      },
      {
        item: "Subwoofer - DBX 25.3 25.4 cm (10) ; 600 W | Hertz",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q33/990j0m999e0-060_1.jpg",
        code: "990J0M999E0-060",
        price: "11990",
      },
      // {
      //   item: "Subwoofer - EBXF 20.5 20.32 cm (8) ; 400 W | Hertz",
      //   img: "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/marutigeniuneaccessories_27112019/old_20210309/r8/990j0m999e0-010_1.jpg",
      //   code: "990J0M999E0-010",
      //   price: "12500",
      // },

      {
        item: "Multimedia Stereo - BT/DVD 15.74 cm (6.2) | Pioneer",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/11/990j0m999d9-220_1.jpg",
        code: "990J0M999D9-220",
        price: "12990",
      },
      {
        item: "Car Amplifier - 2 Channel 400 W All | Hertz",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q33/990j0m999ed-040_1.jpg",
        code: "990J0M999ED-040",
        price: "13350",
      },
      // {
      //   item: "Subwoofer - DBX 30.3 30.48 cm (12) ; 1000 W | Hertz",
      //   img: "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q33/990j0m999e0-070_1.jpg",
      //   code: "990J0M999E0-070",
      //   price: "14350",
      // },
      {
        item: "Car Amplifier - 4 Channel 700 W All | Hertz",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q33/990j0m999ed-050_1.jpg",
        code: "990J0M999ED-050",
        price: "17390",
      },
      {
        item: "Multimedia Stereo - 15.7cm Display| Sony",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2021/04-revised/990j0m83kd9-650_1.jpg",
        code: "990J0M83KD9-650",
        price: "18990",
      },
      {
        item: "Active Subwoofer - DBA 200.3 20.32 cm (8) ; 140 W | Hertz",
        img: "https://www.marutisuzuki.com/assets/images/product-img-01.jpg",
        code: "990J0M999E0-030",
        price: "20350",
      },
      {
        item: "Multimedia Stereo - BT 15.74 cm (6.2) | Pioneer",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/11/990j0m999d9-190_1.jpg",
        code: "990J0M999D9-190",
        price: "20990",
      },
      {
        item: "Multimedia Stereo - CD/USB 15.74 cm (6.2) With Camera",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q20/990j0m67ld9-140_1.jpg",
        code: "990J0M67LD9-140",
        price: "21200",
      },
      {
        item: "Multimedia Stereo - DMH-A345BT (17.2 Cm) | Pioneer",
        img: "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/10/06/990j0m83kd9-700_1-png.png",
        code: "990J0M83KD9-700",
        price: "21900",
      },
      {
        item: "Bass Tube with Amplifier",
        img: "https://marutistoragenew1.azureedge.net/paccmicroservice/msga/990j0m83kd9-700_1.png",
        code: "990J0M999TE-190",
        price: "23490",
      },
      {
        item: "Multimedia Stereo - XAV AX3200 (17.6 cm) | Sony",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/03/990j0m83kd9-670_1.jpg",
        code: "990J0M83KD9-670",
        price: "24990",
      },
      // {
      //   item: "Car Amplifier - 5 Channel 1500 W All | Hertz",
      //   img: "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q33/990j0m999ed-070_1.jpg",
      //   code: "990J0M999ED-070",
      //   price: "25066",
      // },
      {
        item: "Stereo | DMH-Z5290BT | Pioneer",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/noimage/msga_noimage.jpg",
        code: "990J0M999D9-240",
        price: "27990",
      },

      {
        item: "Amplifier Kit (Amplifier with Speakers)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/12/990j0m999te-170_1.jpg",
        code: "990J0M999TE-170",
        price: "28150",
      },
      {
        item: "Multimedia Stereo - Android Auto, Apple Car Play | Sony",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/noimage/msga_noimage.jpg",
        code: "990J0M83KD9-630",
        price: "28990",
      },
      {
        item: "Bass Tube Kit (Amplifier, Bass Tube & Speaker)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/12/990j0m999te-180_1.jpg",
        code: "990J0M999TE-180",

        price: "33990",
      },
    ],
  },
  {
    filedName: "Lifestyle",
    data: [
      {
        item: "Portable Chair - Olive Green | Jimny   ",
        img: "https://marutistoragenew1.azureedge.net/paccmicroservice/msga/msga-noimg.jpg",
        code: "990J0M80TLF-340",
        price: "1710",
      },
      // {
      //   item: "Duct Tape | Jimny",
      //   img: "https://www.marutisuzuki.com/corporate/catalog/parts-images/990J0M80TLF-130.jpg",
      //   code: "990J0M80TLF-130",
      //   price: "435",
      // },
      {
        item: "Premium Mat - Charcoal Black | Jimny",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/09/990j0m80tlf-110_1.jpg",
        code: "990J0M80TLF-110",
        price: "599",
      },
      {
        item: "Emergency Wrap - Charcoal Black | Jimny",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/09/990j0m80tlf-100_1.jpg",
        code: "990J0M80TLF-100",
        price: "199",
      },
      {
        item: "Extreme Weather Sleeping Bag - Olive Green | Jimny",
        img: "https://marutistoragenew1.azureedge.net/paccmicroservice/msga/msga-noimg.jpg",
        code: "990J0M80TLF-280",
        price: "3299",
      },
      {
        item: "Portable Stove - Olive Green | Jimny   ",
        img: "https://marutistoragenew1.azureedge.net/paccmicroservice/990J0M80TLF-270/990j0m80tlf-270_1_t.jpg",
        code: "990J0M80TLF-270",
        price: "2400",
      },
      {
        item: "Emergency Wrap - Olive Green | Jimny",
        img: "https://marutistoragenew1.azureedge.net/paccmicroservice/msga/msga-noimg.jpg",
        code: "990J0M80TLF-300",
        price: "199",
      },
      {
        item: "Premium Expedition Tent - Olive Green | Jimny",
        img: "https://marutistoragenew1.azureedge.net/paccmicroservice/msga/msga-noimg.jpg",
        code: "990J0M80TLF-350",
        price: "6999",
      },
      // {
      //   item: "Portable Stove - Charcoal Black | Jimny  ",
      //   img: "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/09/990j0m80tlf-050_1.jpg",
      //   code: "990J0M80TLF-050",
      //   price: "2400",
      // },
      {
        item: "Premium Camping Mat - Olive Green | Jimny",
        img: "https://marutistoragenew1.azureedge.net/paccmicroservice/msga/msga-noimg.jpg",
        code: "990J0M80TLF-330",
        price: "649",
      },
      {
        item: "Portable Chair - Charcoal Black | Jimny  ",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/09/990j0m80tlf-220_1.jpg",
        code: "990J0M80TLF-220",
        price: "1710",
      },
      {
        item: "Wireless Charger | Grand Vitara (Sigma, Delta, Zeta, Alpha Variant)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/05/990j0m76tm3-010_1.jpg",
        code: "990J0M76TM3-010",
        price: "9990",
      },
      {
        item: "Multi-Purpose Knife | Jimny",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/09/990j0m80tlf-060_1.jpg",
        code: "990J0M80TLF-060",
        price: "479",
      },
      {
        item: "Moderate Weather Sleeping Bag - Charcoal Black | Jimny",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/09/990j0m80tlf-080_1.jpg",
        code: "990J0M80TLF-080",
        price: "1299",
      },
      {
        item: "Extreme Weather Sleeping Bag - Charcoal Black | Jimny  ",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/09/990j0m80tlf-070_1.jpg",
        code: "990J0M80TLF-070",
        price: "3299",
      },
      {
        item: "Rain Poncho - Charcoal Black | Jimny  ",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/09/990j0m80tlf-120_1.jpg",
        code: "990J0M80TLF-120",
        price: "749",
      },
      {
        item: "Portable Table - Charcoal Black | Jimny  ",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/09/990j0m80tlf-010_1.jpg",
        code: "990J0M80TLF-010",
        price: "2199",
      },
      {
        item: "Adventure Tent - Woods Green | Jimny",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/10/990j0m80tlf-180_1.jpg",
        code: "990J0M80TLF-180",
        price: "1999",
      },
      {
        item: "Smart Clean Bin | Jimny",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/09/990j0m80tlf-150_1.jpg",
        code: "990J0M80TLF-150",
        price: "999",
      },
      {
        item: "Premium Camping Mat - Charcoal Black | Jimny  ",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/10/990j0m80tlf-140_1.jpg",
        code: "990J0M80TLF-210",
        price: "649",
      },
      {
        item: "Smart Umbrella - Charcoal Black | Jimny",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/09/990j0m80tlf-200_1.jpg",
        code: "990J0M80TLF-200",
        price: "899",
      },
      {
        item: "Premium Expedition Tent - Charcoal Black | Jimny",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/10/990j0m80tlf-230_1.jpg",
        code: "990J0M80TLF-230",
        price: "6999",
      },
      {
        item: "ORVM Garnish (Grey) | New Baleno (Alpha Zeta & Delta Variant)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/05/25/990j0m55t13-090_1-jpg.jpg",
        code: "990J0M55T13-090",
        price: " 190",
      },
      {
        item: "Emergency Wrap - Charcoal Black | Jimny",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/09/990j0m80tlf-100_1.jpg",
        code: "990J0M80TLF-100",
        price: " 199",
      },
      {
        item: "Luggage Hook  | Jimny",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/09/990j0m80tpq-010_1.jpg",
        code: "990J0M80TPQ-010",
        price: " 200",
      },
      {
        item: "Rear Reflector Garnish (Grey) | New Baleno",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/02/990j0m55t13-170_1.jpg",
        code: "990J0M55T13-170",
        price: " 220",
      },
      // {
      //   item: "Fender Garnish (Black) | New Baleno",
      //   img: "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/05/25/990j0m55t13-150_1-jpg.jpg",
      //   code: "990J0M55T13-150",
      //   price: " 230",
      // },
      {
        code: "990J0M999GR-750",
        item: "Organic Perfume - Spiritual Sandal",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/10/12/990j0m999gr-750_1.jpg",
        price: "MRP: ₹ 799",
      },
      {
        code: "990J0M79M13-040",
        item: "Back Door Garnish (Chrome) | Ciaz",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q29/990j0m79m13-040_1.jpg",
        price: "MRP: ₹ 990",
      },
      {
        code: "990J0M55T13-180",
        item: "Back Door Garnish | New Baleno",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/04/baleno/990j0m55t13-180_0-jpg.jpg",
        price: "MRP: ₹ 650",
      },
      {
        code: "990J0M999G9-060",
        item: "Perfume - Hanging Card (Breeze)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/r9/990j0m999g9-060_1.jpg",
        price: "MRP: ₹ 75",
      },
      {
        code: "990J0M76T13-010",
        item: "Back Door Garnish  | Grand Vitara",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/17/990j0m76t13-010_0.jpg",
        price: "MRP: ₹ 1 090",
      },
      {
        code: "990J0M999M3-470",
        item: "3-in-1 Charging cable",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2022/01/990j0m999m3-470_1.jpg",
        price: "MRP: ₹ 285",
      },
      // {
      //   code: "84790M53M10-5PK",
      //   item: "Garnish Bracket",
      //   img: "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/08/24/84790m53m10-5pk_1.jpg",
      //   price: "MRP: ₹ 56",
      // },
      {
        code: "990J0M999L0-290",
        item: "Neck Rest - Polyphill (Black) | 2 Pieces",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2021/04-revised/990j0m999l0-290_1.jpg",
        price: "MRP: ₹ 699",
      },
      {
        code: "990J0M79M13-020",
        item: "Window Frame Kit (Chrome) | Ciaz",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/10/990j0m79m13-020_1.jpg",
        price: "MRP: ₹ 1,990",
      },
      // {
      //   code: "990J0M999L1-540",
      //   item: "Side Net Pockets",
      //   img: "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/marutigeniuneaccessories/990j0m999l1-540_1.jpg",
      //   price: "MRP: ₹ 99",
      // },
      {
        code: "990J0M999GH-090",
        item: "Perfume - Air Spray (Anti-Tobacco)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q34/990j0m999gh-090_1.jpg",
        price: "MRP: ₹ 183",
      },
      {
        code: "990J0M999GA-040",
        item: "Perfume - Hanging (Sand Rose)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q34/990j0m999ga-040_1.jpg",
        price: "MRP: ₹ 169",
      },
      {
        code: "990J0M999GF-020",
        item: "Perfume - Gel (Green Apple)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/12/990j0m999gf-020_1.jpg",
        price: "MRP: ₹ 395",
      },
      {
        code: "990J0M999GR-270",
        item: "Perfume - Gel (Musk)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q35/990j0m999gr-270_1.jpg",
        price: "MRP: ₹ 250",
      },
      {
        code: "990J0M55T13-080",
        item: "ORVM Garnish |New Baleno (Alpha Zeta & Delta Variant)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/05/25/990j0m55t13-080_1-jpg.jpg",
        price: "MRP: ₹ 240",
      },
      {
        code: "990J0M72S13-020",
        item: "Window Frame Kit | XL6",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/05/31/990j0m72s13-020_0-jpg.jpg",
        price: "MRP: ₹ 2,850",
      },
      {
        code: "990J0M999M3-310",
        item: "Car Mobile Holder (Black)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/10/990j0m999m3-310_1.jpg",
        price: "MRP: ₹ 959",
      },
      {
        code: "990J0M999KC-150",
        item: "Key Cover - Smart Key (Premium Leather Case Nexa)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/r14/990j0m999kc-150_1.jpg",
        price: "MRP: ₹ 599",
      },
      {
        code: "990J0M999M3-350",
        item: "Car Charger - Extension 4 port (Premium gold)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/11/990j0m999m3-350_1.jpg",
        price: "MRP: ₹ 1,399",
      },
      {
        code: "990J0M999L1-470",
        item: "Charging Cable - 2-in-1 Connector",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/r16/990j0m999l1-470_1.jpg",
        price: "MRP: ₹ 1,190",
      },
      // {
      //   code: "990J0M67L11-040",
      //   item: "Interior Lighting - Neon | Wagon R",
      //   img: "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q20/990j0m67l11-040_1.jpg",
      //   price: "MRP: ₹ 550",
      // },
      {
        code: "990J0M999L1-440",
        item: "Nexa Pen",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/r16/990j0m999l1-440_1.jpg",
        price: "MRP: ₹ 550",
      },
      {
        code: "990J0M999L1-450",
        item: "Nexa Pen (Pack of 10)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2021/1602/990j0m999l1-450_1.jpg",
        price: "MRP: ₹ 4,000",
      },

      {
        code: "990J0M999M3-210",
        item: "Car Charger - Fast Charging Twin Port (Black)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q43/990j0m999m3-210_1.jpg",
        price: "MRP: ₹ 599",
      },
      {
        code: "990J0M79M13-080",
        item: "ORVM Garnish (Chrome) | Ciaz",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/10/990j0m79m13-080_1.jpg",
        price: "MRP: ₹ 690",
      },
      // {
      //   code: "990J0M999GR-800",
      //   item: "Glade Gel Car Perfume - Lemon Fragrance",
      //   img: "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/12/990j0m999gr-800_1.png",
      //   price: "MRP: ₹ 105",
      // },
      {
        code: "990J0M999GR-810",
        item: "Glade Gel Car Perfume - Floral Fragrance",
        img: "https://marutistoragenew1.azureedge.net/paccmicroservice/msga/990j0m999gr-810_1.png",
        price: "MRP: ₹ 105",
      },
      {
        code: "990J0M999KC-300",
        item: "Key Cover - Oval Smart Key (Black)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/08/990j0m999kc-300_1.jpg",
        price: "MRP: ₹ 315",
      },
      {
        code: "990J0M79MPE-010",
        item: "Fuel Lid Garnish (Chrome) | Ciaz",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/10/990j0m79mpe-010_1.jpg",
        price: "MRP: ₹ 590",
      },

      {
        code: "990J0M999GR-620",
        item: "Perfume - Gel Super Saver Pack (Lemon)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/07/990j0m999gr-620_1.jpg",
        price: "MRP: ₹ 925",
      },
      {
        code: "990J0M99911-110",
        item: "Logo Projector Light | NEXA",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/03/990j0m99911-110_1.jpg",
        price: "MRP: ₹ 1 249",
      },
      {
        code: "990J0M999KC-320",
        item: "Key Cover - Rectangle Smart Key (Red)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/08/990j0m999kc-320_1.jpg",
        price: "MRP: ₹ 425",
      },
      {
        code: "990J0M76T13-070",
        item: "ORVM Garnish - Chrome | Grand Vitara",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/17/990j0m76t13-070_0.jpg",
        price: "MRP: ₹ 490",
      },
      {
        code: "990J0M76T13-060",
        item: "Head Lamp Garnish - Black | Grand Vitara (Sigma, Delta, Zeta, Alpha Variant)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/17/990j0m76t13-060_0.jpg",
        price: "MRP: ₹ 590",
      },
      {
        code: "990J0M76T13-050",
        item: "Head Lamp Garnish - Smoked | Grand Vitara (Zeta+, Alpha+ Variant)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/17/990j0m76t13-050_0.jpg",
        price: "MRP: ₹ 1 290",
      },
      {
        code: "990J0M76T13-030",
        item: "Front Skid Garnish | Grand Vitara",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/17/990j0m76t13-030_0.jpg",
        price: "MRP: ₹ 650",
      },
      {
        code: "990J0M999KC-280",
        item: "Key Cover - Oval Smart Key (Blue)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/08/990j0m999kc-280-_1.jpg",
        price: "MRP: ₹ 315",
      },
      {
        code: "990J0M999KC-290",
        item: "Key Cover - Oval Smart Key (Red)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/08/990j0m999kc-290_1.jpg",
        price: "MRP: ₹ 315",
      },
      {
        code: "990J0M999KC-400",
        item: "Key Ring - Leather Nexa (Black)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q39/990j0m999kc-400_1.jpg",
        price: "MRP: ₹ 270",
      },
      // {
      //   code: "990J0M55RJ2-120",
      //   item: "Door Handle Protector Carbon Black Finish",
      //   img: "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/marutigeniuneaccessories_27112019/2022/01/990j0m55rj2-120_1.jpg",
      //   price: "MRP: ₹ 699",
      // },
      {
        code: "990J0M99919-170",
        item: "Smart Key Finder - Nexa Black",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/r7/990j0m99919-170_1.jpg",
        price: "MRP: ₹ 990",
      },
      {
        code: "990J0M999GR-500",
        item: "Perfume - Gel (Fresh Spa)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q35/990j0m999gr-500_1.jpg",
        price: "MRP: ₹ 450",
      },

      {
        code: "990J0M76TM3-010",
        item: "Wireless Charger | Grand Vitara (Sigma, Delta, Zeta, Alpha Variant)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/05/990j0m76tm3-010_1.jpg", // Insert image URL if available
        price: "MRP: ₹ 9990",
      },
      {
        code: "990J0M999GR-560",
        item: "Perfume - Gel Ecstar (Lemon)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/12/990j0m999gr-560_1.jpg", // Insert image URL if available
        price: "MRP: ₹ 250",
      },




      {
        code: "990J0M999M3-480",
        item: "Mobile Charger | Dual Charging Port | USB-C Port & USB Port",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/08/24/990j0m999m3-480_1.png", // Insert image URL if available
        price: "MRP: ₹ 1599",
      },
      {
        code: "990J0M999GR-570",
        item: "Perfume - Hanging Super Saver Pack",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2021/04-revised/990j0m999gr-570_1.jpg", // Insert image URL if available
        price: "MRP: ₹ 340",
      },
      {
        code: "990J0M999GR-580",
        item: "Perfume - Gel Super Saver pack",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2021/04-revised/990j0m999gr-580_1.jpg", // Insert image URL if available
        price: "MRP: ₹ 465",
      },
      {
        code: "990J0M999L2-160",
        item: "Cushion - Premium (Black) | 1 Piece",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q41/990j0m999l2-160_1.jpg", // Insert image URL if available
        price: "MRP: ₹ 1070",
      },
      {
        code: "77250M79M10-0PG",
        item: "Trunk Lid Garnish (Chrome) | Ciaz",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q5/77250m79m10-0pg_1.jpg", // Insert image URL if available
        price: "MRP: ₹ 480",
      },
      {
        code: "990J0M76T13-100",
        item: "Tail Lamp Garnish - Smoked | Grand Vitara (Zeta+, Alpha+ Variant)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/17/990j0m76t13-100_0.jpg",
        price: "MRP: ₹ 690",
      },
      {
        code: "990J0M55T13-010",
        item: "Upper Grill Garnish Finish | New Baleno",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/04/baleno/990j0m55t13-010_0-jpg.jpg",
        price: "MRP: ₹ 1050",
      },
      {
        code: "990J0M76T13-150",
        item: "Rear LED Garnish - Smoked | Grand Vitara (Zeta+, Alpha+ Variant)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/17/990j0m76t13-150_0.jpg",
        price: "MRP: ₹ 1390",
      },
      {
        code: "990J0M76T13-120",
        item: "Rear LED Garnish - Chrome | Grand Vitara (Sigma, Delta, Zeta, Alpha Variant)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/05/990j0m76t13-120_1.jpg",
        price: "MRP: ₹ 990",
      },
      {
        code: "990J0M76T13-110",
        item: "Tail Lamp Garnish - Black | Grand Vitara (Sigma, Delta, Zeta, Alpha Variant)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/17/990j0m76t13-110_0.jpg",
        price: "MRP: ₹ 390",
      },
      {
        code: "990J0M999L5-130",
        item: "Tissue Box (Black & White)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q42/990j0m999l5-130_1.jpg",
        price: "MRP: ₹ 550",
      },

      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/r18/990j0m999l5-310_1.jpg",
        item: "Tissue Box (Black)",
        code: "990J0M999L5-180",
        price: "₹ 590",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q42/990j0m999l5-170_1.jpg",
        item: "Tissue Box - Bottle Style (Black)",
        code: "990J0M999L5-170",
        price: "₹ 499",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q40/990j0m999l1-080_1.jpg",
        item: "Car Mobile Holder (Black)",
        code: "990J0M999L1-080",
        price: "₹ 490",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q35/990j0m999gr-480_1.jpg",
        item: "Perfume - Gel (Spice Bomb)",
        code: "990J0M999GR-480",
        price: "₹ 450",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2021/03/1003/990j0m79mu0-040_1.jpg",
        item: "Window Sunshade (Rear Set) | Ciaz",
        code: "990J0M79MU0-040",
        price: "₹ 645",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/02/990j0m55tu0-010_1.jpg",
        item: "Window sunshade (4 Door) | New Baleno",
        code: "990J0M55TU0-010",
        price: "₹ 1 050",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2021/03/1003/990j0m79mu0-040_1.jpg",
        item: "Window Sunshade | Ciaz",
        code: "990J0M79MU0-010",
        price: "₹ 4 100",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/02/990j0m55tu0-020_1.jpg",
        item: "Window sunshade (2 Door) | New Baleno",
        code: "990J0M55TU0-020",
        price: "₹ 590",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/19/990j0m76ttk-040_1.jpg",
        item: "Back Door Lid Garnish | Grand Vitara",
        code: "990J0M76TTK-040",
        price: "₹ 1 490",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/19/990j0m76ttk-020_1.jpg",
        item: "Fender Garnish  | Grand Vitara",
        code: "990J0M76TTK-020",
        price: "₹ 990",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2021/03/1003/990j0m999l2-200_1.jpg",
        item: "Cushion - Memory Foam",
        code: "990J0M999L2-200",
        price: "₹ 890",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/19/990j0m76ttk-050_1.jpg",
        item: "Tyre Pressure Monitoring System | Grand Vitara (Sigma, Delta, Zeta, Zeta+, Alpha Variant)",
        code: "990J0M76TTK-050",
        price: "₹ 5 450",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/12/990j0m999np-010_1.jpg",
        item: "Number Plate Frame",
        code: "990J0M999NP-010",
        price: "₹ 499",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/08/24/990j0m999l5-260_1.jpg",
        item: "Tissue Box (Beige)",
        code: "990J0M999L5-260",
        price: "₹ 599",
      },

      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/05/990j0m76tu0-020_1.jpg",
        item: "Window Sunshade (2 Rear Door) | Grand Vitara",
        code: "990J0M76TU0-020",
        price: "₹ 590",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/17/990j0m76tu0-010_0.jpg",
        item: "Window Sunshade (4 Door) | Grand Vitara",
        code: "990J0M76TU0-010",
        price: "₹ 1,150",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/05/990j0m76tu0-030_1.jpg",
        item: "Window Sunshade (Rear Windshield) | Grand Vitara",
        code: "990J0M76TU0-030",
        price: "₹ 460",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/r18/990j0m999l5-280_1.jpg",
        item: "Tissue Box - Nexa (Beige)",
        code: "990J0M999L5-280",
        price: "₹ 599",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2021/03/1003/990j0m72ru0-010_1.jpg",
        item: "Window Sunshade (Front & Rear Set) | Ertiga/XL6",
        code: "990J0M72RU0-010",
        price: "₹ 1,299",
      },
      // {
      //   img: "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q6/77832m68k10-0pg_1.jpg",
      //   item: "Car Emblem (A-Star)",
      //   code: "77832M68K10-0PG",
      //   price: "₹ 71",
      // },

      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q34/990j0m999ga-030_1.jpg",
        item: "Perfume - Hanging (Tangerine)",
        code: "990J0M999GA-030",
        price: "₹ 169",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/05/25/990j0m55t07-120_1-jpg.jpg",
        item: "Rear Windshield Garnish | New Baleno",
        code: "990J0M55T07-120",
        price: "₹ 1 850",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q32/990j0m99923-040_1.jpg",
        item: "High Performance Indo Vacuum Cleaner",
        code: "990J0M99923-040",
        price: "₹ 1 990",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q32/990j0m99923-010_1.jpg",
        item: "Portable Vacuum Cleaner",
        code: "990J0M99923-010",
        price: "₹ 1 190",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/09/990j0m79m15-010_1.jpg",
        item: "Tweeter Garnish (Silver) | Ciaz",
        code: "990J0M79M15-010",
        price: "₹ 1 350",
      },
      // {
      //   img: "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/02/990j0m55t07-100_1.jpg",
      //   item: "Rear Upper Spoiler Accent (Midnight Black) | New Baleno",
      //   code: "990J0M55T07-100",
      //   price: "₹ 690",
      // },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q42/990j0m999l2-500_1.jpg",
        item: "Cushion (Daring Black) | 1 Piece",
        code: "990J0M999L2-500",
        price: "₹ 1 050",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/19/990j0m76tm9-010_1.jpg",
        item: "Trunk Sill Guard | Grand Vitara",
        code: "990J0M76TM9-010",
        price: "₹ 2,590",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q43/990j0m999m3-170_1.jpg",
        item: "Car Charger - Fast Charging Twin Port (Black)",
        code: "990J0M999M3-170",
        price: "₹ 449",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2021/04-revised/990j0m999l2-530_1.jpghttps://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2021/04-revised/990j0m999l2-530_1.jpg",
        item: "Cushion - Lumbar Back Support (Black)",
        code: "990J0M999L2-530",
        price: "₹ 1,010",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/r10/990j0m999gr-240_1.jpg",
        item: "Perfume - Gel Godrej Aer (Musk)",
        code: "990J0M999GR-240",
        price: "₹ 425",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/r10/990j0m999gr-210_1.jpg",
        item: "Perfume - Gel Godrej Aer (Petal Crush)",
        code: "990J0M999GR-210",
        price: "₹ 425",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/r10/990j0m999gr-220_1.jpg",
        item: "Perfume - Gel Godrej Aer (Lush Green)",
        code: "990J0M999GR-220",
        price: "₹ 425",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/11/29/990j0m56rp9-010_1.jpg",
        item: "SMART DOOR HANDLE GARNISH - BRIGHT CHROME | (DZIRE, IGNIS, ERTIGA, BALENO)",
        code: "990J0M56RP9-010",
        price: "₹ 1,350",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/11/29/990j0m56rp9-020_1.jpg",
        item: "SMART DOOR HANDLE GARNISH - BLACK CHROME | (DZIRE, IGNIS, ERTIGA, BALENO)",
        code: "990J0M56RP9-020",
        price: "₹ 1,890",
      },
      // {
      //   img: "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q40/990j0m999l1-290_1.jpg",
      //   item: "Travel Bag",
      //   code: "990J0M999L1-290",
      //   price: "₹ 6,444",
      // },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/r10/990j0m999gr-140_1.jpg",
        item: "Perfume - AC Vent Godrej Aer (Lush Green)",
        code: "990J0M999GR-140",
        price: "₹ 320",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/r16/990j0m999l1-360_1.jpg",
        item: "Nexa Menu Card (Black)",
        code: "990J0M999L1-360",
        price: "₹ 255",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q25/990j0m72rp4-010_1.jpg",
        item: "Tail Lamp Garnish (Chrome) | Ertiga",
        code: "990J0M72RP4-010",
        price: "₹ 2,290",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/r10/990j0m999gr-160_1.jpg",
        item: "Perfume - AC Vent Godrej Aer (Musk)",
        code: "990J0M999GR-160",
        price: "₹ 320",
      },
      // {
      //   img: "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/marutigeniuneaccessories/990j0m999l2-560_1.jpg",
      //   item: "Car Headrest - Cockpit Style (Black and Beige)",
      //   code: "990J0M999L2-560",
      //   price: "₹ 4,250",
      // },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/05/25/990j0m55t13-090_1-jpg.jpg",
        item: "ORVM Garnish (Grey) | New Baleno (Alpha Zeta & Delta Variant)",
        code: "990J0M55T13-090",
        price: "₹ 190",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/02/990j0m55t13-040_1.jpg",
        item: "Fog Lamp Garnish (Grey) | New Baleno",
        code: "990J0M55T13-040",
        price: "₹ 310",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/08/24/990j0m999l3-070_1.jpg",
        item: "Cushion - Seat Belt Cover (Black & Beige) | 2 Pieces",
        code: "990J0M999L3-070",
        price: "₹ 399",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2021/1602/990j0m999l3-080_1.jpg",
        item: "Cushion - Seat Belt Cover (Carbon Fibre Silver) | 2 Pieces",
        code: "990J0M999L3-080",
        price: "₹ 399",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q40/990j0m999l0-240_1.jpg",
        item: "Travel Pillow (Dark Beige)",
        code: "990J0M999L0-240",
        price: "₹ 675",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/05/25/990j0m56r13-230_1-jpg.jpg",
        item: "Door Handle Garnish - Single Key Hole | New Baleno (Delta & Sigma Variant) \\ Dzire",
        code: "990J0M56R13-230",
        price: "₹ 1,490",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/05/31/990j0m55t13-140_0-png.png",
        item: "Fender Garnish | New Baleno",
        code: "990J0M55T13-140",
        price: "₹ 310",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/02/990j0m55t13-130_1.jpg",
        item: "Rear Bumper Garnish (Grey) | New Baleno",
        code: "990J0M55T13-130",
        price: "₹ 670",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/08/24/990j0m999l3-070_1.jpg",
        item: "Cushion - Seat Belt Cover Reversible(Black & Organge) | 2 Pieces",
        code: "990J0M999L3-040",
        price: "₹ 399",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/12/990j0m999l1-600_1.jpg",
        item: "Car iPad/Tab/Mobile Holder - Rear Seat",
        code: "990J0M999L1-600",
        price: "₹ 845",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/04/22/990j0m999gr-710_1-jpg.jpg",
        item: "LEMON GEL PERFUME",
        code: "990J0M999GR-710",
        price: "₹ 120",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/04/baleno/990j0m55t13-160_0-jpg.jpg",
        item: "Rear Reflector Garnish | New Baleno",
        code: "990J0M55T13-160",
        price: "₹ 290",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2021/03/1003/990j0m66ru0-010_1.jpg",
        item: "Window Curtains - 4 Door | Ignis",
        code: "990J0M66RU0-010",
        price: "₹ 950",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/11/990j0m66ru0-020_1.jpg",
        item: "Window Sunshade (Rear) | Ignis",
        code: "990J0M66RU0-020",
        price: "₹ 570",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q43/990j0m999m3-230_1.jpg",
        item: "Car Charger - Fast Charging (Black) | Tomtom",
        code: "990J0M999M3-230",
        price: "₹ 630",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2021/03/0203/990j0m66ru0-030_1.jpg",
        item: "Window Curtain - Rear Door | Ignis",
        code: "990J0M66RU0-030",
        price: "₹ 570",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/r14/990j0m999kc-220_1.jpg",
        item: "Key Cover - Smart Key (Premium Leather Case Nexa)",
        code: "990J0M999KC-220",
        price: "₹ 449",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/r14/990j0m999kc-230_1.jpg",
        item: "Key Ring - Leather (Black)",
        code: "990J0M999KC-230",
        price: "₹ 399",
      },
      // {
      //   img: "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q42/990j0m999l3-110_1.jpg",
      //   item: "Cushion - Seat Belt Cover Nexa (Black) | 2 Pieces",
      //   code: "990J0M999L3-110",
      //   price: "₹ 449",
      // },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/05/990j0m99911-100_1-jpg.jpg",
        item: "Logo Projector Light | Arena",
        code: "990J0M99911-100",
        price: "₹ 1 349",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/r12/990j0m999h2-400_1.jpg",
        item: "Ecstar Cockpit Spray (500 ml)",
        code: "990J0M999H2-400",
        price: "₹ 639",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/08/990j0m999kc-380_1.jpg",
        item: "Key Cover - Rectangle Smart Key (Silver)",
        code: "990J0M999KC-380",
        price: "₹ 425",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/08/990j0m999kc-330_1.jpg",
        item: "Key Cover - Rectangle Smart Key (Black)",
        code: "990J0M999KC-330",
        price: "₹ 425",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/r9/990j0m999g9-070_1.jpg",
        item: "Perfume - Hanging Card (Lemon)",
        code: "990J0M999G9-070",
        price: "₹ 75",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/08/990j0m999kc-340_1.jpg",
        item: "Key Cover - Small Key (Blue)",
        code: "990J0M999KC-340",
        price: "₹ 325",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/r16/990j0m999l2-050_1.jpg",
        item: "Cushion - Neck Support (Beige) | 2 Pieces",
        code: "990J0M999L2-050",
        price: "₹ 920",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/r16/990j0m999l2-060_1.jpg",
        item: "Cushion - Back Support Memory Foam (Beige)",
        code: "990J0M999L2-060",
        price: "₹ 960",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/07/990j0m74t07-130_0.jpg",
        item: "Front Bumper Painted Garnish - Black + Red | Fronx",
        code: "990J0M74T07-130",
        price: "₹ 890",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/r11/990j0m999gr-510_1.jpg",
        item: "Perfume - Organic (Minty Blast)",
        code: "990J0M999GR-510",
        price: "₹ 560",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/12/990j0m999gr-520_1.jpg",
        item: "Perfume - Organic (Spice Bomb)",
        code: "990J0M999GR-520",
        price: "₹ 560",
      },
      // {
      //   img: "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/08/24/990j0m99919-180_1.jpg",
      //   item: "Smart Key Finder - Arena Black",
      //   code: "990J0M99919-180",
      //   price: "₹ 990",
      // },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/09/990j0m999m3-430_1.jpg",
        item: "Car Charger - Extension 4 port (Black)",
        code: "990J0M999M3-430",
        price: "₹ 999",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q34/990j0m999gh-110_1.jpg",
        item: "Perfume - Air Spray (Lemon)",
        code: "990J0M999GH-110",
        price: "₹ 183",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q41/990j0m999l2-170_1.jpg",
        item: "Cushion - Premium (Beige) | 1 Piece",
        code: "990J0M999L2-170",
        price: "₹ 990",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/07/990j0m74tpj-030_0.jpg",
        item: "ORVM Cover - Red Dash Finish | Fronx",
        code: "990J0M74TPJ-030",
        price: "₹ 2 690",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q34/990j0m999gd-060_1.jpg",
        item: "Perfume - Gel (Squash)",
        code: "990J0M999GD-060",
        price: "₹ 375",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q34/990j0m999gd-010_1.jpg",
        item: "Perfume - Gel (Lemon)",
        code: "990J0M999GD-010",
        price: "₹ 375",
      },
      {
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q41/990j0m999l2-140_1.jpg",
        item: "Cushion - Back Support Memory Foam (Dark Beige)",
        code: "990J0M999L2-140",
        price: "₹ 960",
      },
      {
        img: "https://marutistoragenew1.azureedge.net/paccmicroservice/msga/msga-noimg.jpg",
        item: "Stone Protection Film | Ciaz",
        code: "990J0M79MH0-020",
        price: "₹ 690",
      },
    ],
  },
  {
    filedName: "Safety and Security",
    data: [
      {
        item: "LAMP ASSEMBLY_FLOOR",
        img: "https://marutistoragenew1.azureedge.net/paccmicroservice/36550M64M00/36550m64m00_t.jpg",
        code: "36550M64M00",
        price: "152",
      },
      {
        item: "Plug 'N' Play Footwell light- White color",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2022/01/36550m64m50_1.jpg",
        code: "36550M64M30",
        price: "171",
      },
      {
        item: "Plug 'N' Play Footwell light- Blue color",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2022/01/36550m64m50_1.jpg",
        code: "36550M64M50",
        price: "270",
      },
      {
        item: "Car Reverse Camera for Multimedia | XL6",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/10/990j0m72s21-020_1.jpg",
        code: "990J0M72S21-020",
        price: "4190",
      },
      {
        item: "Fog Lamp | Jimny (Zeta)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/10/990j0m80t11-010_0.jpg",
        code: "990J0M80T11-010",
        price: "4800",
      },
      {
        item: "Tyre Pressure Monitoring System | Grand Vitara (Sigma, Delta, Zeta, Zeta+, Alpha Variant)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/19/990j0m76ttk-050_1.jpg",
        code: "990J0M76TTK-050",
        price: "5450",
      },
      {
        item: "Front Parking Assist System | Fronx",
        code: "990J0M74T21-010",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/07/990j0m74t21-010_1.jpg",
        price: " 5 650",
      },
      {
        item: "Front Parking Sensors | Grand Vitara",
        code: "990J0M76T21-010",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/09/05/990j0m76t21-010_1.jpg",
        price: " 5 690",
      },
      {
        item: "Front Parking Sensor | New Baleno",
        code: "990J0M72R21-010",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/02/990j0m72r21-010_1-jpg.jpg",
        price: " 5 790",
      },
      {
        item: "Fog Lamp | New Baleno (Sigma & Delta Variant)",
        code: "990J0M55T11-010",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/04/baleno/990j0m55t11-010_0-jpg.jpg",
        price: " 6 090",
      },
      {
        item: "Front Parking Assist System | Jimny",
        code: "990J0M80T21-010",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2023/10/990j0m80t21-010_1.jpg",
        price: " 6 500",
      },
      {
        item: "Bracket Tuner",
        code: "39321M65D00",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/12/39321m65d00_1.jpg",
        price: " 63",
      },
      {
        item: "Battery Coin Cell (CR-2032) - For Security Lock Remote",
        code: "990J0M99921-020",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q32/990j0m99921-020_1.jpg",
        price: " 94",
      },
      {
        item: "Battery Coin Cell (CR-1620) - For OE Fitted Security Lock Remote",
        code: "990J0M99921-010",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q32/990j0m99921-010_1.jpg",
        price: " 100",
      },
      {
        item: "Valve Cap (Silver) - 5 Pieces",
        code: "990J0M999WO-200",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q44/990j0m999wo-200_1.jpg",
        price: " 125",
      },
      {
        item: "Valve Cap (Black) - 5 Pieces",
        code: "990J0M999WO-210",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/partsimages/2022/08/24/990j0m999wo-210_1.jpg",
        price: " 125",
      },
      {
        item: "Remote Security Hardware Kit",
        code: "99000M99537-RMT",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/07/99000m99537-rmt_1.jpg",
        price: " 490",
      },
      {
        item: "Xtreme Vision Bulb - H412V 60/55W",
        code: "990J0M99911-010",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/r7/990j0m99911-010_1.jpg",
        price: " 543",
      },
      {
        item: "Xtreme Vision Bulb - H712V 60/55W",
        code: "990J0M67L11-050",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/q20/990j0m67l11-050_1.jpg",
        price: " 640",
      },
      // {
      //   item: "Air Inflator - Analog",
      //   code: "990J0M999W0-280",
      //   img: "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/marutigeniuneaccessories_27112019/2021/03/0203/990j0m999w0-280_1.jpg",
      //   price: " 1,850",
      // },
      {
        item: "Steering Lock",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/r7/990j0m99914-010_1.jpg",
        code: "990J0M99914-010",
        price: "1890",
      },
      {
        item: "Tyre Air Inflator - Digital 2-in-1 with built-in Torch",
        img: "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/marutigeniuneaccessories_27112019/2020/10/990j0m999w0-130_1.jpg",
        code: "990J0M999W0-130",
        price: "2111",
      },
      {
        item: "2-In-1 Car Vacuum Cleaner & Air Inflator",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/10/990j0m999w0-130_1.jpg",
        code: "990J0M99923-060",
        price: "2499",
      },
      // {
      //   item: "Reverse Parking Assistance System with Display (White)",
      //   img: "https://marutisuzukiarenaprodcdn.azureedge.net/-/media/marutigeniuneaccessories_27112019/2021/1602/990j0m74k09-100_1.jpg",
      //   code: "990J0M74K09-100",
      //   price: "4010",
      // },
      {
        item: "Rear Parking Assistance System - Buzzer & Display",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2021/02/990j0m74k09-020_1.jpg",
        code: "990J0M74K09-020",
        price: "4290",
      },
      {
        item: "Car Reverse Camera for Multimedia (Black)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/r7/990j0m99909-150_1.jpg",
        code: "990J0M99909-150",
        price: "5390",
      },
      {
        item: "Car Reverse Camera for Multimedia (Silky Silver)",
        img: "https://marutistoragenew1.azureedge.net/paccmicroservice/msga/990j0m99909-120_1.jpg",
        code: "990J0M99909-120",
        price: "5390",
      },
      {
        item: "Car Reverse Camera for Multimedia (Arctic White)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/2020/07/990j0m99909-130_1.jpg",
        code: "990J0M99909-130",
        price: "5390",
      },
      {
        item: "Car Reverse Camera for Multimedia (Superior White)",
        img: "https://az-ci-afde-prd-arena-01-e7fmh3dxacbgeyh5.z01.azurefd.net/-/media/marutigeniuneaccessories_27112019/old_20210309/r7/990j0m99909-140_1.jpg",
        code: "990J0M99909-140",
        price: "5390",
      },
    ],
  },
];
